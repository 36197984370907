import React, { useEffect, useState } from "react";
import { curriculumActions, teacherActions } from "../../../_actions";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { Header } from "../../../_components/Admin/Header";
import { CricleProcess } from "../../../_components/Process";
import { apiCaller, history } from "../../../_helpers";
import { isEmpty } from "lodash";
import { teacherService } from "../../../_services";
import { curriculumConstants } from "../../../_constants";
import { convertSkillVN } from "../../../_helpers/utils";

function ViewDetailCurriculumListSkill() {
  const { id, unit_id } = useParams();

  // useEffect(() => {
  //   dispatch(teacherActions.getDetailCurriculumById(id));
  // }, []);
  const dispatch = useDispatch();
  const curriculums = useSelector((state) => state.curriculums);
  const curriculumsUnitName = useSelector(
    (state) => state.curriculums.detail.data_lesson.unit_name
  );

  const lessonSkill = useSelector((state) => state.curriculums.lesson_skill);
  let currentCurriculum = curriculumsUnitName?.find((item) => item.unit_id === unit_id);
  const authentication = useSelector((state) => state.authentication);
  const search = history?.location?.search;
  const params = new URLSearchParams(search);
  const fromPage = params?.get("page");
  const subType = params.get("subType");
  const isComposition = params.get("isComposition");
  const compositionId = params.get("compositionId");
  const [offsetTopic, setOffsetTopic] = useState(0);
  const [limitTopic] = useState(10);
  const [isLoading, setIsLoading] = useState(true)
  const [isHasMoreTopic, setIsHasMoreTopic] = useState(true)

  useEffect(() => {
    if(subType) {
      setIsHasMoreTopic(true);
      setIsLoading(true);
      setOffsetTopic(0);
      if (isComposition) {
        getDataComposition();
      } else {
        if (compositionId) {
          getTopicByComposition(compositionId, 0)
        } else {
          getTopic(0);
        }
      }
    } else {
      // teacherService.getLessonBySkillTeacher(unit_id).then((res) => {
      //   if (res) {
      //     dispatch({
      //       type: curriculumConstants.LESSON_BY_SKILL_TEACHER,
      //       lesson_skill: res,
      //     });
      //   }
      // });
      curriculumActions.getLessonByUnit(unit_id).then((res) => {
          const listSkill = res?.skill_list?.map(item => {
            return {
              ...item,
              list_lesson: item?.list_lesson?.map(lesson => {
                return {
                  ...lesson,
                  unit_id,
                  unit_name: currentCurriculum?.unit_name,
                  curriculum_id: currentCurriculum?.curriculum_id,
                  curriculum_name: currentCurriculum?.curriculum_name
                }
              })
            }
          })
          dispatch({
            type: curriculumConstants.LESSON_BY_SKILL_TEACHER,
            lesson_skill: {
              data: listSkill,
              base_url: res?.base_url
            },
          });
      });
    }
  }, [isComposition, compositionId]);

  const handleScroll = (e) => {
    if (e.target.clientHeight + e.target.scrollTop + 5 >= e.target.scrollHeight && isHasMoreTopic && !!subType) {
      setOffsetTopic(offsetTopic + limitTopic);
      if (compositionId) {
        getTopicByComposition(compositionId, offsetTopic + limitTopic, true)
      } else {
        getTopic(offsetTopic + limitTopic, true);
      }
    }
  }

  const getTopic = (offset, isGetMore) => {
    apiCaller(
      `/api_preparation_course/list_topic?unit_id=${unit_id}&curriculum_id=${id}&offset=${offset}&limit=${limitTopic}`, "GET").then((res) => {
      dispatch({
        type: curriculumConstants.GET_DETAIL_CURRICULUM,
        curriculum: {
          ...curriculums?.detail,
          data_lesson: {
            ...curriculums?.detail?.data_lesson,
            unit_name: curriculums?.detail?.data_lesson?.unit_name?.map(item => ({
              ...item,
              list_topic: isGetMore ? [...item?.list_topic, ...res?.data?.list_topic] : res?.data?.list_topic
            }))
          }
        },
      });
      if (res?.data?.list_topic?.length < limitTopic) {
        setIsHasMoreTopic(false)
      }
      setIsLoading(false)
    })
  }

  const getTopicByComposition = (composition_id, offset, isGetMore) => {
    apiCaller(
      `/api_preparation_course/list_topic?unit_id=${unit_id}&curriculum_id=${id}&composition_id=${composition_id}&offset=${offset}&limit=${limitTopic}`, "GET").then((res) => {
      dispatch({
        type: curriculumConstants.GET_DETAIL_CURRICULUM,
        curriculum: {
          ...curriculums?.detail,
          data_lesson: {
            ...curriculums?.detail?.data_lesson,
            unit_name: curriculums?.detail?.data_lesson?.unit_name?.map(item => ({
              ...item,
              list_topic: isGetMore ? [...item?.list_topic, ...res?.data?.list_topic] : res?.data?.list_topic
            }))
          }
        },
      });
      if (res?.data?.list_topic?.length < limitTopic) {
        setIsHasMoreTopic(false)
      }
      setIsLoading(false)
    })
  }

  const getDataComposition = () => {
    apiCaller('/api_preparation_course/composition?unit_id=' + unit_id + "&curriculum_id=" + id, "GET").then((res) => {
      dispatch({
        type: curriculumConstants.GET_DETAIL_CURRICULUM,
        curriculum: {
          ...curriculums?.detail,
          ...curriculums?.detail,
          data_lesson: {
            ...curriculums?.detail?.data_lesson,
            unit_name: curriculums?.detail?.data_lesson?.unit_name?.map(item => ({
              ...item,
              list_composition: res?.data
            }))
          }
        },
      });
      setIsLoading(false)
    })
  }

  return (
    <div className="sunE-right-container">
      <Header title={compositionId ? currentCurriculum?.list_composition?.find(item => item?.composition_id == compositionId)?.name : (currentCurriculum?.unit_name || '')} isBack />
      <div className="sunE-container-box giaotrinh unit">
        <div className="flex-m">
          <div className="flex-2 sunE-giaotrinh-list scrollbar-custom pad-center-large" onScroll={handleScroll}>
            {!isEmpty(lessonSkill?.data) && !subType &&
              lessonSkill?.data?.map((data, i) => {
                return (
                  <Link
                    to={
                      "/" +
                      authentication.role +
                      "/curriculum/view/detail/" +
                      id +
                      "/list_skill/" +
                      unit_id +
                      "/list_exercise/" +
                      data.skill +
                      (!isEmpty(fromPage) ? `?page=${fromPage}` : "")
                    }
                    key={i}
                  >
                    {i === 0 ? (
                      <div
                        className={
                          "box-giaotrinh-gr-first box-shadow padding_box_giaotrinh_gr_frist"
                        }
                      >
                        <img
                          src={
                            "/assets/images/giaotrinh/" +
                            (data.skill === "test" || data.skill === "exam"
                              ? "mini_test"
                              : data.skill) +
                            ".png"
                          }
                          alt={data.skill}
                        />
                        <h2 className="text-trans">{convertSkillVN(data?.skill)}</h2>
                      </div>
                    ) : (
                      <div className="box-giaotrinh-gr rel box-shadow">
                        <div className="thumb">
                          <img
                            src={
                              "/assets/images/giaotrinh/" +
                              (data.skill === "test" || data.skill === "exam"
                                ? "mini_test"
                                : data.skill) +
                              ".png"
                            }
                            alt={data.skill}
                          />
                        </div>
                        <div className="box-select-info flex-1">
                          <p className="text-trans semibold">
                            {convertSkillVN(data?.skill)}
                          </p>
                        </div>
                        {/* { CricleProcess(40) } */}
                      </div>
                    )}
                  </Link>
                );
              })}
            {!isEmpty(currentCurriculum?.list_topic) && !!subType && !isComposition && !isLoading &&
              currentCurriculum?.list_topic?.map((item, index) => (
                <Link
                  to={
                    "/" +
                    authentication.role +
                    "/curriculum/view/detail/" +
                    id +
                    "/list_skill/" +
                    unit_id +
                    "/list_exercise/" +
                    encodeURIComponent(item?.topic_name) + `?subType=${subType}` +
                    (!isEmpty(fromPage) ? `&page=${fromPage}` : "") + 
                    (`&topicId=${item?.topic_id}`)
                  }
                  key={index}
                >
                  <div className="box-giaotrinh-gr rel box-shadow" style={{marginLeft: 0}}>
                    <div className="box-select-info flex-1">
                      <p className="text-trans semibold">
                        {item?.topic_name}
                      </p>
                    </div>
                  </div>
                </Link>
              ))
            }
            {!isEmpty(currentCurriculum?.list_composition) && !!subType && !!isComposition && !isLoading &&
              currentCurriculum?.list_composition?.map((item, index) => (
                <Link
                  to={
                    "/" +
                    authentication.role +
                    "/curriculum/view/detail/" +
                    id +
                    "/list_skill/" +
                    unit_id + '?' +
                    (!isEmpty(fromPage) ? `page=${fromPage}` : "") +
                    (!!subType ? `&subType=${subType}` : '') +
                    `&compositionId=${item?.composition_id}`
                  }
                  key={index}
                >
                  <div className="box-giaotrinh-gr rel box-shadow" style={{marginLeft: 0}}>
                    <div className="box-select-info flex-1">
                      <p className="text-trans semibold">
                        {item?.name}
                      </p>
                    </div>
                  </div>
                </Link>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export { ViewDetailCurriculumListSkill };
