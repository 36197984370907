import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link, useLocation } from "react-router-dom";
import { Header } from "../../../_components/Admin/Header";
import { teacherConstants } from "../../../_constants";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { SelectDate } from "../../../_components/Calendar";
import { isEmpty } from "lodash";
import { apiCaller } from "../../../_helpers";
import { ReviewExam } from "../../StudentPage/exam-test/ReviewExam";
import { convertSingleQuote } from "../../../_helpers/utils";
import LessonBase from "../../../_base/LessonBase";
import DoTestExam from "../../StudentPage/exam-test/DoTestExam";
import { formatExamDataQuestions, formatLessonDataQuestion } from "../../../_helpers/formatDataLesson";
import { Alert } from "../../../_components/Alert";
import { alertActions } from "../../../_actions";

function DetailSkillExercise() {
  const exercises = useSelector((state) => state.classes.exercises);
  const originAttachFile = useSelector(
    (state) => state.classes.originAttachFile
  );
  const authentication = useSelector((state) => state.authentication);
  const { id, skill_key } = useParams();
  const skill = exercises.data_exercise[skill_key - 1];
  const dateAddStudentExercise = useSelector(
    (state) => state.classes.dateAddStudentExercise
  );
  let temp_exercises_data_exercise = exercises.data_exercise;
  const [inputs, setInputs] = useState({
    detail: skill?.detail,
    exercise_type: skill?.exercise_type,
    list_guide_id: skill?.list_guide_id,
    parent: skill?.parent,
    start_time: skill?.start_time ?? dateAddStudentExercise.start_time,
    end_time: skill?.end_time ?? dateAddStudentExercise.end_time,
  });

  const dispatch = useDispatch();
  const history = useHistory();
  let { start_time, end_time } = inputs;
  const getPrevTime = useSelector((state) => state.classes.originTime);
  const statusAttachFile = useSelector(
    (state) => state.classes.statusAttachFile
  );
  const alert = useSelector((state) => state.alert);
  const search = history.location.search;
  const params = new URLSearchParams(search);
  const fromPage = params.get("page");

  const [isEdit, setIsEdit] = useState(exercises?.isNew ?? false);
  const [isShowReview, setIsShowReview] = useState(false);
  const [isShowReviewLesson, setIsShowReviewLesson] = useState(false);
  const [dataQuestion, setDataQuestion] = useState([]);
  const [infoQuestion, setInfoQuestion] = useState();
  const [examSelected, setExamSelected] = useState()

  const handlepageshow = () => {
    $(".loading").addClass("hide");
  };

  useEffect(() => {
    window.addEventListener("pageshow", handlepageshow);

    return () => {
      window.removeEventListener("pageshow", handlepageshow);
    };
  }, []);

  useEffect(() => {
    if (!statusAttachFile) {
      dispatch({
        type: teacherConstants.ADD_FILE_ATTACH_ORIGIN,
        data: exercises.data_exercise[skill_key - 1]?.list_guide_id,
      });
    } else {
      setInputs((inputs) => ({
        ...inputs,
        start_time: getPrevTime.start_time ?? exercises.start_time,
        end_time: getPrevTime.end_time ?? exercises.end_time,
      }));
    }
    // dispatch({ type: teacherConstants.ON_STATUS_ATTACH_FILE });
  }, []);

  function handleChangeDate(date) {
    setIsEdit(true);
    if (date.start_time) {
      if (
        moment(date.start_time).toDate() <=
        moment(inputs.end_time, "DD-MM-YYYY").toDate()
      ) {
        setInputs((inputs) => ({
          ...inputs,
          start_time:
            moment(date.start_time).format("DD-MM-YYYY") || inputs.start_time,
        }));
        dispatch({
          type: teacherConstants.ADD_DATA_EXERCISE,
          data: {
            ...exercises,
            start_time:
              moment(date.start_time).format("DD-MM-YYYY") || inputs.start_time,
          },
        });
      }
    } else if (date.end_time) {
      if (
        moment(date.end_time).toDate() >=
        moment(inputs.start_time, "DD-MM-YYYY").toDate()
      ) {
        setInputs((inputs) => ({
          ...inputs,
          end_time:
            moment(date.end_time).format("DD-MM-YYYY") || inputs.end_time,
        }));
        dispatch({
          type: teacherConstants.ADD_DATA_EXERCISE,
          data: {
            ...exercises,
            end_time:
              moment(date.end_time).format("DD-MM-YYYY") || inputs.end_time,
          },
        });
      }
    }
    // setInputs(inputs => ({
    //     ...inputs,
    //     start_time: date.start_time ? moment(date.start_time).format("DD-MM-YYYY") : inputs.start_time,
    //     end_time: date.end_time ? moment(date.end_time).format("DD-MM-YYYY") : inputs.end_time,
    // }));
  }

  const [listFileId, setListFileId] = useState(
    exercises?.data_exercise[skill_key - 1]?.list_guide_id
  );

  function removeFile(key) {
    setIsEdit(true);
    let newArrayDeleted = listFileId?.filter((_, index) => index !== key);
    setListFileId([...newArrayDeleted]);
    if (!!exercises.data_exercise[skill_key - 1].list_guide_id)
      exercises.data_exercise[skill_key - 1].list_guide_id = newArrayDeleted;
    exercises.isNew = true;
    dispatch({
      type: teacherConstants.ADD_DATA_EXERCISE,
      data: exercises,
    });
  }

  function handleSubmit() {
    if (validateParam()) {
      temp_exercises_data_exercise[skill_key - 1].start_time = start_time;
      temp_exercises_data_exercise[skill_key - 1].end_time = end_time;
      dispatch({
        type: teacherConstants.ADD_DATA_EXERCISE,
        data: {
          ...exercises,
          data_exercise: temp_exercises_data_exercise,
          start_time: inputs.start_time,
          end_time: inputs.end_time,
          isNew: false,
        },
      });

      dispatch({ type: teacherConstants.OFF_STATUS_ATTACH_FILE });
      history.push(
        "/" +
          authentication.role +
          "/class/view/" +
          id +
          "/exercise/review" +
          (!isEmpty(fromPage) ? `?page=${fromPage}` : "")
      );
    }
  }

  function validateParam() {
    return new Date(inputs.start_time) > new Date(inputs.end_time)
      ? false
      : true;
  }

  const handleBack = () => {
    if (!!exercises.data_exercise[skill_key - 1].list_guide_id)
      exercises.data_exercise[skill_key - 1].list_guide_id = originAttachFile;
    dispatch({
      type: teacherConstants.ADD_DATA_EXERCISE,
      data: {
        ...exercises,
        data_exercise: temp_exercises_data_exercise,
        start_time: getPrevTime.startTime,
        end_time: getPrevTime.endTime,
        isNew: false,
      },
    });
    dispatch({ type: teacherConstants.OFF_STATUS_ATTACH_FILE });
    history.replace(
      "/" +
        authentication.role +
        "/class/view/" +
        id +
        "/exercise/review" +
        (!isEmpty(fromPage) ? `?page=${fromPage}` : "")
    );
  };

  // Handle Go List Support
  const handleGoListSupport = () => {
    dispatch({ type: teacherConstants.ON_STATUS_ATTACH_FILE });
    // dispatch({
    //   type: teacherConstants.ADD_TIME_TO_CHOOSE_FILE_SCREEN,
    //   data: {
    //     start_time: inputs.start_time,
    //     end_time: inputs.end_time,
    //   },
    // });
  };

  const handleReview = () => {
    const type = exercises.data_exercise[skill_key - 1]?.detail?.lesson_type;
    if(type === 'exam' || type === 'mini_test') {
        getDataExam(type)
        return;
    }
    getDataLesson()
    return;
  }

  const getDataLesson = () => { 
    const lessonId = exercises.data_exercise[skill_key - 1]?.detail?.lesson_id || exercises.data_exercise[skill_key - 1]?.exercise_id;
    const url = `/api_lesson/lesson?id=${lessonId}&unit_id=${exercises.data_exercise[skill_key - 1]?.detail?.unit_id}&curriculum_id=${exercises.data_exercise[skill_key - 1]?.detail?.curriculum_id}`;
    apiCaller(url, 'GET')
    .then(res=>{
      if(res.status){
        const convertDataQuestion = formatLessonDataQuestion(res)
          setDataQuestion(convertDataQuestion);
          setInfoQuestion(res?.lesson);
          setIsShowReviewLesson(true);
          setIsShowReview(true);
      } else {
        dispatch(
          alertActions.error({
            message: res?.msg?.toString(),
            screen: 'DETAIL_SKILL_ASSIGN',
            isShowPopup: true
          })
        );
      }
    })
    .catch (err => {
      dispatch(
        alertActions.error({
          message: err?.toString(),
          screen: 'DETAIL_SKILL_ASSIGN',
          isShowPopup: true
        })
      );
    })
  }
  const getDataExam = (type) => {
    const examId = exercises.data_exercise[skill_key - 1]?.detail?.lesson_id || exercises.data_exercise[skill_key - 1]?.detail?.exam_id || exercises.data_exercise[skill_key - 1]?.exercise_id;
    const url = `/student/api_exam/exam_v2?id=${examId}&type=${type}&class_id=${exercises?.class_id||0}&unit_id=${exercises.data_exercise[skill_key - 1]?.detail?.unit_id||0}&is_homework=0&curriculum_id=${exercises.data_exercise[skill_key - 1]?.detail?.curriculum_id}`;
    apiCaller(url, 'GET')
    .then(res=>{
        if(res.status){
          const convertDataQuestion = formatExamDataQuestions(res)
          setDataQuestion(convertDataQuestion);
          setInfoQuestion(res?.lesson);
          setIsShowReview(true)
        } else {
          dispatch(
            alertActions.error({
              message: res?.msg?.toString(),
              screen: 'DETAIL_SKILL_ASSIGN',
              isShowPopup: true
            })
          );
        }
    })
    .catch (err => {
      dispatch(
        alertActions.error({
          message: err?.toString(),
          screen: 'DETAIL_SKILL_ASSIGN',
          isShowPopup: true
        })
      );
    })
  }

  function learn() {
    const dataExercise = exercises.data_exercise[skill_key - 1]?.detail;
    if (!!dataExercise?.lesson_type &&
      ["exam", "mini_test"].includes(dataExercise.lesson_type)
    ) {
      setExamSelected(dataExercise);
    } else {
      $(".loading").removeClass("hide");
      LessonBase._moveLesson(
        LessonBase.Type.teacher,
        authentication,
        dataExercise,
        null,
        9
      );
    }
  }

  return (
    <div className='sunE-right-container flex-column'>
      <Header 
        title={"Chi tiết bài"} 
        isBack 
        clickBack={handleBack} 
        component={
          <>
            {/* <div
              className={"mr-10 rel"}
            >
              <button className="btn-line-blue" onClick={learn}>Xem bài tập</button>
            </div> */}
            <div
              className={"mr-10 rel"}
            >
              <button className="btn-line-blue" onClick={handleReview}>Xem đáp án</button>
            </div>
          </>
        }
      />
      <div className='sunE-container-box giaobai-detail canhan flex-1'>
        <div className='sunE-giaobai'>
          <div className='box-shadow box-info-giaobai'>
            <h2 className='title'>{skill?.detail?.lesson_name}</h2>
            <h3 className='desc'>{skill?.detail?.lesson_topic}</h3>
            <p className='text-black semibold text_detail'>
              {skill?.parent[0]} &gt;&gt; {skill?.parent[1]} &gt;&gt;{" "}
              {skill?.exercise_type?.capitalize()}{" "}
            </p>
          </div>
        </div>
        <h2 className='title-semi'>Thời gian làm bài</h2>
        <div className='flex-m class-slect-time mb0 class_slect_time_margin flex-start '>
          <div className='pr-25'>
            <div className=' text-align-center sunE-input-group re slect-time'>
              <label>Ngày bắt đầu</label>
              <div
                className={
                  "select-gr select-time-center text-align-center" +
                  (inputs.start_time > inputs.end_time ? " err" : "")
                }
              >
                <SelectDate
                  showTimeSelect={false}
                  timeIntervals={15}
                  dateFormat='dd/MM/yyyy'
                  selected={
                    inputs?.start_time
                      ? new Date(inputs?.start_time)
                      : new Date()
                  }
                  onChange={(start_time) => {
                    if (
                      new Date(start_time) <= new Date(inputs.end_time) &&
                      new Date(start_time) >= moment(new Date()).startOf("day")
                    ) {
                      setIsEdit(true);
                      setInputs((inputs) => ({
                        ...inputs,
                        start_time:
                          moment(start_time).format("YYYY-MM-DD") ||
                          inputs.start_time,
                      }));
                      // dispatch({
                      //   type: teacherConstants.ADD_TIME_TO_CHOOSE_FILE_SCREEN,
                      //   data: {
                      //     start_time: moment(start_time).format("YYYY-MM-DD"),
                      //     end_time: inputs.end_time,
                      //   },
                      // });
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className=''>
            <div className=' text-align-center sunE-input-group re slect-time'>
              <label>Ngày kết thúc</label>
              <div
                className={
                  "select-gr  select-time-center text-align-center" +
                  (inputs.start_time > inputs.end_time ? " err" : "")
                }
              >
                <SelectDate
                  showTimeSelect={false}
                  timeIntervals={15}
                  dateFormat='dd/MM/yyyy'
                  selected={
                    inputs?.end_time ? new Date(inputs.end_time) : new Date()
                  }
                  onChange={(end_time) => {
                    if (new Date(inputs.start_time) <= new Date(end_time)) {
                      setIsEdit(true);
                      setInputs((inputs) => ({
                        ...inputs,
                        end_time:
                          moment(end_time).format("YYYY-MM-DD") ||
                          inputs.end_time,
                      }));
                      // dispatch({
                      //   type: teacherConstants.ADD_TIME_TO_CHOOSE_FILE_SCREEN,
                      //   data: {
                      //     start_time: inputs.start_time,
                      //     end_time: moment(end_time).format("YYYY-MM-DD"),
                      //   },
                      // });
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='flex-m mb-10'>
          <div className='flex-1'>
            <h2 className='title-semi'>File Hướng dẫn</h2>
          </div>
          <Link
            to={
              "/" +
              authentication.role +
              "/class/view/" +
              id +
              "/exercise/list_file_support/" +
              skill_key +
              (!isEmpty(fromPage) ? `?page=${fromPage}` : "")
            }
            onClick={() => handleGoListSupport()}
          >
            <div className='flex-1 text-right'>
              <h2 className='title-semi blue-main underline'>Thêm hướng dẫn</h2>
            </div>
          </Link>
        </div>
        <div className='file-support-list scrollbar-custom'>
          {(listFileId || []).map((data, i) => {
            return (
              <div className='flex-m mb-10' key={i}>
                <div className='flex-1'>
                  <div className='file-support flex-m black'>
                    <div>
                      <img
                        src='/assets/images/teacher/giaobai/ico_file.png'
                        alt='ico_file'
                      />
                    </div>
                    <div className='flex-1'>
                      <span className='light'>{data.title}</span>
                    </div>
                  </div>
                </div>
                <div className='w-remove'>
                  <img
                    src='/assets/images/teacher/ico_remove_blue.png'
                    alt='ico_remove_blue'
                    className='btn-remove'
                    onClick={() => removeFile(i)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className='form-sunE-button text-right'>
        <button
          className={
            "btn-line-blue" +
            ((!validateParam() && !isEdit) || !isEdit ? " btn-disable" : "")
          }
          onClick={handleSubmit}
          disabled={!isEdit}
        >
          Lưu
        </button>
      </div>
      <ReviewExam
        examInfo={infoQuestion}
        isShow={isShowReview}
        onClose={() => setIsShowReview(false)}
        examId={exercises.data_exercise[skill_key - 1]?.detail?.lesson_id}
        listQuestions={dataQuestion}
        isShowResultLesson={isShowReviewLesson}
        isReview={true}
      />
      {examSelected && (
        <DoTestExam
          setExamSelected={setExamSelected}
          isShow={examSelected !== null}
          onClose={() => setExamSelected(null)}
          mockTestId={
            examSelected?.lesson_id ||
            examSelected?.exercise_id ||
            examSelected?.exam_id
          }
          type={examSelected.lesson_type}
          examSelected={examSelected}
          heading={examSelected?.exam_name || examSelected?.name || examSelected?.lesson_name || examSelected?.exercise_name || ''}
        />
      )}
      {alert.message && alert.screen === 'DETAIL_SKILL_ASSIGN' && 
        <Alert alert={alert} />
      }
    </div>
  );
}

export { DetailSkillExercise };
