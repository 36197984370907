import React, { useState } from "react";
import { CheckBoxCustom, renderStatusCheckBox } from "../../../_components/exam-test/CheckBoxCustom";
import { validWord } from "../../../_helpers/utils";

export default function ResultQuestionPickGroup({dataQuestion, answer, skill, question_type, numberReview}) {
  let finalAnswer = JSON.parse(answer);
  const listData = [];

  dataQuestion[0]?.list_option?.map((item) => {
    item?.match_option_text?.map(texts => {
      texts?.split(',')?.map(text => {
        if (!!text) {
          listData.push(text)
        }
      })
    })
  })

  const renderItem = (item, index) => {
    const userChoice = finalAnswer?.find(ans => validWord(ans?.words) === validWord(item))

    return (
      <div style={{paddingLeft: 6}}>
        <p>
          {(!!numberReview ? numberReview : (index + 1)) + '. ' + item}
        </p>
        {dataQuestion[0]?.list_option?.map((option, i) => (
          <div style={{display: 'flex', gap: 8}} key={i}>
            <div style={{width: 24}}>
              {option?.match_option_text?.some(op => op?.includes(item)) && !userChoice?.score && <img
                src="/assets/images/ico_hover_answer.png"
                style={{
                  width: 24,
                  height: 24,
                }}
              />}
            </div>
            <CheckBoxCustom
              status={renderStatusCheckBox(
              // userChoice?.score > 0 && option?.match_option_text?.some(op => op?.includes(item)),
              validWord(option?.option_text) === validWord(userChoice?.user_choice),
              option?.match_option_text?.some(op => op?.includes(item)),
              true
              )}
              label={option?.option_text}
            />
          </div>
        ))}
      </div>
    )
  }
  return (
    <div style={{flex: 1, marginTop: 16, display: 'flex', flexDirection: 'column', gap: 32}}>
      {listData?.map((item,index) => {
        return (
          <div key={index}>
            {renderItem(item, index)}
          </div>
        )
      })}
    </div>
  )
}