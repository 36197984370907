import { useState } from 'react';
import RootCheckBox from '../RootCheckBox';
import './style.css';
import moment from 'moment';
import { apiCaller, history } from '../../_helpers';
import InputDate from '../Auth/InputDate';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions } from '../../_actions';
import { configConstants, teacherConstants } from '../../_constants';
import InputText from '../Auth/InputText';
import { isEmail } from '../../_helpers/validateEmail';
import { isPhone } from '../../_helpers/validatePhone';
import { useParams } from 'react-router-dom';
import { Alert } from '../Alert';
import { isValidDate } from '../../_helpers/utils';

export default function PopupEditStudent({ open = false, data, onClose, onComplete, onSubmit, isEditStudentFail, formatBirthday, isDetail = false}) {
  if(!open) {
    return null;
  }

  const { id } = useParams();
  const classes = useSelector((state) => state.classes);
  const [name, setName] = useState(data?.name || '');
  const [nameError, setNameError] = useState('');
  const [birthday, setBirthday] = useState(data?.birthday && isValidDate(moment(data?.birthday, formatBirthday || 'YYYY-MM-DD hh:mm:ss').toDate()) ? moment(data?.birthday, formatBirthday || 'YYYY-MM-DD hh:mm:ss').toDate() : undefined);
  const [birthdayError, setBirthdayError] = useState('')
  const [gender, setGender] = useState(data?.gender);
  const [email, setEmail] = useState(data?.email || "");
  const [emailError, setEmailError] = useState("");
  const [phone, setPhone] = useState(data?.phone || "");
  const [phoneError, setPhoneError] = useState("");
  const [nickName, setNickName] = useState(data?.nick_name || "");
  const [username, setUsername] = useState(data?.username || "");
  const [usernameError, setUsernameError] = useState("");
  const [password, setPassword] = useState(data?.password || "");
  const [passwordError, setPasswordError] = useState("");
  const [isChanged, setIsChanged] = useState(false)
  const [isViewDetail, setIsViewDetail] = useState(isDetail)
  const dispatch = useDispatch()

  const changeName = (value) => {
    if (nameError) {
      setNameError("");
    }
    setIsChanged(true)
    setName(value);
  };

  const changeNickname = (value) => {
    setIsChanged(true)
    setNickName(value);
  };

  const changeUsername = (value) => {
    if (usernameError) {
      setUsernameError("");
    }
    setIsChanged(true)
    setUsername(value.trim());
  };

  const changePassword = (value) => {
    if (passwordError) {
      setPasswordError("");
    }
    setIsChanged(true)
    setPassword(value.trim());
  };

  const changeBirthday = (date) => {
    let error = ''
    const newDate = dayjs(date).set('hour', 0).set('minute', 0).set('second', 0);
    const currentDate = dayjs(new Date()).set('hour', 0).set('minute', 0).set('second', 0);
    if(newDate.isAfter(currentDate)) {
      error = 'Ngày sinh không được ở tương lai';
    };
    setIsChanged(true)
    setBirthdayError(error)
    setBirthday(date);
  };

  const changeEmail = (value) => {
    if (emailError) {
      if (value.length >= 6) {
        if(!isEmail(value)) {
          setEmailError("Định dạng email không đúng");
        } else {
          setEmailError("");
        }
      }
    }
    setIsChanged(true)
    setEmail(value.trim());
  };

  const changePhone = (value) => {
    const regex = /^[0-9\b]+$/;
    if(!regex.test(value) && value !== '') {
      return;
    }
    if (phoneError) {
      if (isPhone(value)) {
        setPhoneError("");
      }
    }
    setIsChanged(true)
    setPhone(value.trim());
  };

  const checkPhone = () => {
    if (phone.length && phone.length !== 10 && phone.length !== 11) {
      setPhoneError("Số điện thoại hợp lệ có từ 10-11");
    }
    if (phone && phone.length && phone[0] !== "0") {
      setPhoneError("Định dạng số điện thoại không đúng");
    }
    setIsChanged(true)
  }

  const checkEmail = () => {
    if (!!email.length && !isEmail(email)) {
      setEmailError("Định dạng email không đúng");
    }
    if (!!email.length && email.length < 6) {
      setEmailError("Email cần tối thiểu 6 kí tự");
    }
    setIsChanged(true)
  };

  const onBlurField = (type) => {
    switch (type) {
      case "name": {
        if (!name && !nameError) {
          setNameError("Họ và tên không được để trống");
        }
        return
      }
      case "email": {
        if (email && !emailError) {
          checkEmail();
        }
        return;
      }
      case "phone": {
        if (phone && !phoneError) {
          checkPhone();
        }
        return;
      }
      case "username": {
        if (!username && !usernameError && !isEditStudentFail) {
          setUsernameError("Tên đăng nhập không được để trống");
        }
        return;
      }
      case "password": {
        if (!password && !passwordError && !isEditStudentFail) {
          setPasswordError("Mật khẩu không được để trống");
        }
        return;
      }
      default: {
        return;
      }
    }
  };

  const handleSubmit = async () => {
    if(!validateParams()) {
      return;
    }
    
    const newDataStudent = {
      ...data,
      user_id: data?.id,
      name,
      birthday: !!birthday ? dayjs(birthday).format('DD-MM-YYYY') : undefined,
      gender: gender ? gender : undefined,
      email: email ? email : undefined,
      phone: phone ? phone : undefined,
      nick_name: nickName ? nickName : undefined,
      username,
      password
    }
    const dataSubmit = {
      class_id: id,
      ...newDataStudent
    }
    if(onSubmit) {
      onSubmit(dataSubmit);
      return;
    }
    try {
      const res = await apiCaller('/api_class/edit_students', 'post', dataSubmit)
      if(res?.status) {
        dispatch({
          type: teacherConstants.GET_STUDENT_OFF_CLASS,
          students: {
            ...classes.students,
            data: classes.students?.data?.map(item => {
              if (item?.id === data?.id) {
                return {
                  ...item,
                  ...newDataStudent,
                  birthday: !!birthday ? dayjs(birthday).format('YYYY-MM-DD hh:mm:ss') : undefined
                }
              }
              return item;
            })
          },
        });
        dispatch(
          alertActions.success({
            message: res?.msg,
            screen: teacherConstants.SCREEN_LIST_STUDENT,
            onCompletePopup: () => {
              onClose();
            }
          })
        );
      }
    } catch (error) {
      dispatch(
        alertActions.error({
          message: error?.toString(),
          screen: teacherConstants.SCREEN_LIST_STUDENT,
          isShowPopup: true
        })
      );
    }
  }

  const validateParams = () => {
    if (isEditStudentFail) {
      return !!name && !nameError && isChanged
    }
    return !!name && !!username && !!password && !nameError && !birthdayError && !emailError && !phoneError && !usernameError && !passwordError;
  }

  const renderIconName = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="#00bbb5" d="M12 4a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2m0 7c2.67 0 8 1.33 8 4v3H4v-3c0-2.67 5.33-4 8-4m0 1.9c-2.97 0-6.1 1.46-6.1 2.1v1.1h12.2V17c0-.64-3.13-2.1-6.1-2.1"/></svg>
    )
  }

  const renderIconDate = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 20 20">
        <path 
          fill="#00bbb5" 
          d="M5.673 0a.7.7 0 0 1 .7.7v1.309h7.517v-1.3a.7.7 0 0 1 1.4 0v1.3H18a2 2 0 0 1 2 1.999v13.993A2 2 0 0 1 18 20H2a2 2 0 0 1-2-1.999V4.008a2 2 0 0 1 2-1.999h2.973V.699a.7.7 0 0 1 .7-.699M1.4 7.742v10.259a.6.6 0 0 0 .6.6h16a.6.6 0 0 0 .6-.6V7.756zm5.267 6.877v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zm-8.333-3.977v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zM4.973 3.408H2a.6.6 0 0 0-.6.6v2.335l17.2.014V4.008a.6.6 0 0 0-.6-.6h-2.71v.929a.7.7 0 0 1-1.4 0v-.929H6.373v.92a.7.7 0 0 1-1.4 0z"
        />
      </svg>
    )
  }

  const renderIconEmail = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="#00bbb5" d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2zm-2 0l-8 5l-8-5zm0 12H4V8l8 5l8-5z"/></svg>
    )
  }

  const renderIconPhone = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="#00bbb5" fill-rule="evenodd" d="M5.733 2.043c1.217-1.21 3.221-.995 4.24.367l1.262 1.684c.83 1.108.756 2.656-.229 3.635l-.238.238a.65.65 0 0 0-.008.306c.063.408.404 1.272 1.832 2.692s2.298 1.76 2.712 1.824a.7.7 0 0 0 .315-.009l.408-.406c.876-.87 2.22-1.033 3.304-.444l1.91 1.04c1.637.888 2.05 3.112.71 4.445l-1.421 1.412c-.448.445-1.05.816-1.784.885c-1.81.169-6.027-.047-10.46-4.454c-4.137-4.114-4.931-7.702-5.032-9.47l.749-.042l-.749.042c-.05-.894.372-1.65.91-2.184zm3.04 1.266c-.507-.677-1.451-.731-1.983-.202l-1.57 1.56c-.33.328-.488.69-.468 1.036c.08 1.405.72 4.642 4.592 8.492c4.062 4.038 7.813 4.159 9.263 4.023c.296-.027.59-.181.865-.454l1.42-1.413c.578-.574.451-1.62-.367-2.064l-1.91-1.039c-.528-.286-1.146-.192-1.53.19l-.455.453l-.53-.532c.53.532.529.533.528.533l-.001.002l-.003.003l-.007.006l-.015.014a1 1 0 0 1-.136.106c-.08.053-.186.112-.319.161c-.27.101-.628.155-1.07.087c-.867-.133-2.016-.724-3.543-2.242c-1.526-1.518-2.122-2.66-2.256-3.526c-.069-.442-.014-.8.088-1.07a1.5 1.5 0 0 1 .238-.42l.032-.035l.014-.015l.006-.006l.003-.003l.002-.002l.53.53l-.53-.531l.288-.285c.428-.427.488-1.134.085-1.673z" clip-rule="evenodd"/></svg>
    )
  }

  const renderIconPassword = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="#00bbb5" d="M12 17a2 2 0 0 1-2-2c0-1.11.89-2 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2m6 3V10H6v10zm0-12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V10c0-1.11.89-2 2-2h1V6a5 5 0 0 1 5-5a5 5 0 0 1 5 5v2zm-6-5a3 3 0 0 0-3 3v2h6V6a3 3 0 0 0-3-3"/></svg>
    )
  }

  const renderLicenseType = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
        <path 
          fill="#00bbb5" 
          d="M12 13q-1.25 0-2.125-.875T9 10t.875-2.125T12 7t2.125.875T15 10t-.875 2.125T12 13M6 23v-7.725q-.95-1.05-1.475-2.4T4 10q0-3.35 2.325-5.675T12 2t5.675 2.325T20 10q0 1.525-.525 2.875T18 15.275V23l-6-2zm6-7q2.5 0 4.25-1.75T18 10t-1.75-4.25T12 4T7.75 5.75T6 10t1.75 4.25T12 16m-4 4.025L12 19l4 1.025v-3.1q-.875.5-1.888.788T12 18t-2.113-.288T8 16.926zm4-1.55"
        />
      </svg>
    )
  }

  const renderLicenseDuration = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 512 512"><path fill="#00bbb5" d="M256 23c-3.7 0-7.4.1-11.1.27l.8 17.98c3.4-.16 6.8-.25 10.3-.25c118.8 0 215 96.2 215 215s-96.2 215-215 215c-89.6 0-166.35-54.7-198.65-132.6l27.63-8.3l-48.43-34.3l-19.05 54.5l22.55-6.7C74.68 428.8 158.4 489 256 489c128.6 0 233-104.4 233-233S384.6 23 256 23m-30.8 2.04c-13.3 1.75-26.1 4.6-38.6 8.48l5.6 17.09c11.4-3.54 23.3-6.15 35.4-7.75zm-57 15.12c-12.4 5.05-24.2 11.12-35.4 18.12l9.5 15.21c10.3-6.44 21.2-12.03 32.6-16.67zM116.4 69.5a234 234 0 0 0-29.35 26.12l13.05 12.28c8.3-8.77 17.4-16.81 27-24.06l-4.8-6.57zm69.5 8.58l-4.4 17.44l217 55.48l4.4-17.4zM74.07 110.5c-8.19 10.2-15.54 21.2-21.94 32.7l15.65 8.8c5.91-10.7 12.69-20.8 20.26-30.3zm127.63 8.8c-3.9 26 2.8 55.2 14.2 79.2c6.4 13.4 14.2 25.2 21.9 33.8c4.2 4.7 8.4 8.3 12.2 10.9l-5.4 21.2c-4.6.4-10 1.6-16 3.7c-10.9 3.8-23.4 10.4-35.4 19.1c-21.6 15.6-41.4 37.9-50.4 62.6l167.5 42.9c3.9-26-2.8-55.2-14.2-79.2c-6.4-13.4-14.2-25.2-21.9-33.8c-4.2-4.7-8.4-8.3-12.2-10.9l5.4-21.2c4.5-.5 10-1.6 16-3.7c10.9-3.8 23.4-10.4 35.4-19.1c21.6-15.6 41.4-37.9 50.4-62.6zM43.24 160.9c-5.33 12-9.7 24.4-13 37.3l17.48 4.2c3.03-11.8 7.04-23.2 11.95-34.2zM26.2 217.5C24.11 230 23 242.9 23 256v.9l18-.2v-.7c0-12.1 1.02-24 2.95-35.6zM113.5 361l-4.4 17.4l217 55.5l4.4-17.4z"/></svg>
    )
  }

  return (
    <div id="modal-center" className="uk-flex-top uk-modal uk-flex uk-open edit-student-xls-modal" uk-modal="">
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical" 
        style={{ 
          width: 800, 
          maxHeight: '80vh',
          paddingLeft: 0, 
          paddingRight: 0, 
          display: 'flex', 
          flexDirection: 'column' 
        }}
      >
        <div className='edit-student-xls-header'>
          <span>{isViewDetail ? 'Thông tin chi tiết học sinh' : 'Sửa thông tin học sinh'}</span>
        </div>
        <div className='edit-student-xls-body scrollbar-custom'>
          <div className="info-student-avatar">
            <img
              src={configConstants.BASE_URL + (data?.avatar ? data.avatar : `assets/img_base/student_${gender ? gender : 'male'}_dfa.jpg`)}
              alt="avatar"
            />
          </div>
          <div className='d-flex gap-16' >
            <div className='flex-1'>
              <label className='edit-student-xls-label'>Họ và tên</label>
              <InputText
                value={name}
                setValue={changeName}
                type="text"
                name="name"
                placeholder="Nhập họ và tên"
                renderLabelIcon={renderIconName}
                errorText={nameError}
                typeErrText='underAbsolute'
                onBlur={() => {
                  onBlurField("name");
                }}
                readOnly={isViewDetail}
              />
            </div>
            <div className='flex-1'>
              <label className='edit-student-xls-label'>Bí danh</label>
              <InputText
                value={nickName}
                setValue={changeNickname}
                type="text"
                name="nickName"
                placeholder="Nhập bí danh"
                renderLabelIcon={renderIconName}
                readOnly={isViewDetail}
              />
            </div>
          </div>
          <div className='d-flex gap-16'>
            <div className='flex-1'>
              <label className='edit-student-xls-label'>Ngày sinh</label>
              <InputDate
                label={'Ngày sinh'}
                styleContainer={{flex: 1}}
                value={birthday}
                setValue={changeBirthday}
                name="birthday"
                renderLabelIcon={renderIconDate}
                errorText={birthdayError}
                errorAbsolute={true}
                placeholder={"Chọn ngày sinh"}
                // popperPlacement='top'
                typeErrText='underAbsolute'
                readOnly={isViewDetail}
              />
            </div>
            <div style={{width: '30%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <label className='edit-student-xls-label'>Giới tính</label>
              <div className='d-flex gap-16'>
                <div className='radio-item' style={{height: 44, display: 'flex', alignItems: 'center', margin: 0}}>
                  <input
                    id='male'
                    className="pointer_cursor"
                    type="radio"
                    name="radio-group"
                    defaultValue="male"
                    style={{height: 20, width: 20, margin: '0 8px 0 0'}}
                    checked={gender === "male"}
                    onChange={() => {setIsChanged(true);setGender("male")}}
                    disabled={isViewDetail}
                  />
                  <label htmlFor="male" style={{cursor: 'pointer', fontSize: 16}}>Nam</label>
                </div>
                <div className='radio-item' style={{height: 44, display: 'flex', alignItems: 'center', paddingLeft: 16, margin: 0}}>
                  <input
                    id='female'
                    className="pointer_cursor"
                    type="radio"
                    name="radio-group"
                    defaultValue="female"
                    style={{height: 20, width: 20, margin: '0 8px 0 0'}}
                    checked={gender === "female"}
                    onChange={() => {setIsChanged(true);setGender("female")}}
                    disabled={isViewDetail}
                  />
                  <label htmlFor="female" style={{cursor: 'pointer', fontSize: 16}}>Nữ</label>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex gap-16' >
            <div className='flex-1'>
              <label className='edit-student-xls-label'>Email</label>
              <InputText
                value={email}
                setValue={changeEmail}
                type="text"
                name="email"
                placeholder="Nhập email"
                renderLabelIcon={renderIconEmail}
                errorText={emailError}
                typeErrText='underAbsolute'
                onBlur={() => {
                  onBlurField("email");
                }}
                readOnly={isViewDetail}
              />
            </div>
            <div className='flex-1'>
              <label className='edit-student-xls-label'>Số điện thoại</label>
              <InputText
                value={phone}
                setValue={changePhone}
                type="text"
                name="phone"
                placeholder="Nhập số điện thoại"
                renderLabelIcon={renderIconPhone}
                errorText={phoneError}
                typeErrText='underAbsolute'
                onBlur={() => {
                  onBlurField("phone");
                }}
                readOnly={isViewDetail}
              />
            </div>
          </div>
          {isViewDetail && (
            <div className='d-flex gap-16' >
              <div className='flex-1'>
                <label className='edit-student-xls-label'>Loại license</label>
                <InputText
                  value={data?.license_type}
                  placeholder="Loại license"
                  // setValue={changeUsername}
                  type="text"
                  name="license_type"
                  renderLabelIcon={renderLicenseType}
                  readOnly={isViewDetail}
                />
              </div>
              <div className='flex-1'>
                <label className='edit-student-xls-label'>Thời hạn license</label>
                <InputText
                  value={data?.license_duration ? (data?.license_duration + ' ngày') : " "}
                  placeholder="Thời hạn license"
                  // setValue={changePassword}
                  type="text"
                  name="license_duration"
                  renderLabelIcon={renderLicenseDuration}
                  readOnly={isViewDetail}
                />
              </div>
            </div>
          )}
          <div className='d-flex gap-16' >
            <div className='flex-1'>
              <label className='edit-student-xls-label'>Tên đăng nhập</label>
              <InputText
                value={username}
                setValue={changeUsername}
                type="text"
                name="username"
                placeholder="Nhập tên đăng nhập"
                renderLabelIcon={renderIconName}
                errorText={usernameError}
                typeErrText='underAbsolute'
                onBlur={() => {
                  onBlurField("username");
                }}
                readOnly={isViewDetail}
              />
            </div>
            <div className='flex-1'>
              <label className='edit-student-xls-label'>Mật khẩu</label>
              <InputText
                value={password}
                setValue={changePassword}
                type="text"
                name="password"
                placeholder="Nhập mật khẩu"
                renderLabelIcon={renderIconPassword}
                errorText={passwordError}
                typeErrText='underAbsolute'
                onBlur={() => {
                  onBlurField("password");
                }}
                readOnly={isViewDetail}
              />
            </div>
          </div>
        </div>
        <div className='edit-student-xls-action'>
          <button className="btn-default mr-20" style={{width: 'unset'}} onClick={onClose}>{isViewDetail ? 'Đóng' : 'Huỷ'}</button>
          {isViewDetail ? <button 
            className={"btn-bg-blue btn-default"} 
            onClick={() => {
              if (data?.username === '*******' && data?.password === '******') {
                dispatch(
                  alertActions.error({
                    message: "Bạn không có quyền chỉnh sửa thông tin học sinh này",
                    screen: teacherConstants.SCREEN_LIST_STUDENT,
                    isShowPopup: true
                  })
                );
                return;
              }
              setIsViewDetail(false)
            }} 
            style={{width: 'unset'}} 
          >
            Chỉnh sửa
          </button> : <button 
            disabled={!validateParams()} 
            className={"btn-bg-blue btn-default " + (!validateParams() ? 'btn-disable' : '')} 
            onClick={handleSubmit} 
          >
            Lưu
          </button>}
        </div>
      </div>
    </div>
  )
}