import React, { useEffect, useState } from "react";
import { Header } from "../../_components/Admin/Header";
import { SideBarAssessment } from "../../_components/Admin/Student";
import { studentService } from "../../_services";
import Slider from "react-slick";
import { studentConstants } from "../../_constants";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import LazyLoad from "react-lazyload";
import moment from 'moment'

const ChuaCoText = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function HomePageOfflineAssessment() {
  const [offlineAssessment, setOfflineAssessment] = useState({});
  const authentication = useSelector((state) => state.authentication);
  const assessmentsData = useSelector((state) => state.assessments);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch()

  useEffect(() => {
    if (!!assessmentsData?.dataAssessmentOffline && !!assessmentsData?.timeGetData?.assessmentOffline &&
      moment(new Date()).diff(moment(assessmentsData?.timeGetData?.assessmentOffline), 'hours') < 1
    ) {
      setOfflineAssessment(assessmentsData?.dataAssessmentOffline)
    } else {
      getData()
    }
  }, []);

  const getData = () => {
    setLoading(true);
    studentService.getOfflineAssessment().then((data) => {
      setOfflineAssessment(data);
      dispatch({
        type: studentConstants.GET_TIME_ASSESSMENT_OFFLINE,
        payload: new Date()
      })
      dispatch({
        type: studentConstants.GET_ASSESSMENT_OFFLINE,
        payload: data
      })
      setLoading(false);
    });
  }

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        onClick={onClick}
        className={className}
        style={{
          border: "1px solid #E5E5E5",
        }}
      >
        <img alt="dropdown right" src="/assets/images/icon/ico_right.png" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        onClick={onClick}
        className={className}
        style={{
          border: "1px solid #E5E5E5",
        }}
      >
        <img src="/assets/images/icon/ico_left.png" alt="ico_left_white" />
      </div>
    );
  }
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  function showAcademic(score) {
    if (typeof score === "string" && score === "") {
      return "Chưa có";
    }
    if (score >= 8) {
      return "Học sinh giỏi";
    } else if (score >= 6.5) {
      return "Học sinh khá";
    } else if (score >= 5) {
      return "Học sinh trung bình";
    } else if (score >= 3.5) {
      return "Học sinh yếu";
    } else {
      return "Học sinh kém";
    }
  }

  function showDiligence(score) {
    if (score === studentConstants.POINT_A) {
      return (
        <img
          src="/../assets/images/student/thanhtich/ico_chamchi.png"
          alt="ico_chamchi"
        />
      );
    } else if (score === studentConstants.POINT_B) {
      return (
        <img
          src="/../assets/images/student/thanhtich/ico_cancu.png"
          alt="ico_chamchi"
        />
      );
    } else if (score === studentConstants.POINT_C) {
      return (
        <img
          src="/../assets/images/student/thanhtich/ico_cancu.png"
          alt="ico_chamchi"
        />
      );
    } else {
      return (
        <ChuaCoText className="flex-1 text-center badge-student">
          <span>Chưa có</span>
        </ChuaCoText>
      );
    }
  }

  return (
    <div className="sunE-right-container flex-column">
      <Header title={"Thành tích"} component={<>
        <div className="refresh-curriculum-btn-teacher-header" style={{width: 40, height: 40, marginRight: 8}} onClick={getData}>
          <img src="/assets/images/icon_refresh_curriculum.png" style={{width: '100%', height: '100%', borderRadius: '50%'}}/>
        </div>
        </>}
      />
      <div className="flex-1 flex-m sunE-content thanhtich-container">
        <SideBarAssessment />

        <div className="flex-1 thanhtich-box-content">
          {!isLoading &&
            (isEmpty(offlineAssessment) ? (
              <div className="thanhtich-offline-box box-shadow">
                <div className="no-item-2 text-center">
                  <img
                    src="/../assets/images/student/thanhtich/img_no_item.png"
                    alt="img_no_item"
                  />
                  <p>Bạn chưa có lớp học Offline nào</p>
                </div>
              </div>
            ) : (
              <LazyLoad className="slider-custom custom-4">
                <ul className="uk-slider-items uk-child-width-1-1 uk-child-width-1-1@s uk-child-width-1-1@m">
                  <Slider {...settings}>
                    {!isEmpty(offlineAssessment.data) ? (
                      offlineAssessment.data.map((data, i) => {
                        return (
                          <li key={i}>
                            <div className="thanhtich-offline-box border-1-light">
                              <div className="top-title-name two-line">
                                <p>{data.class_name}</p>
                              </div>
                              <div className="thanhtich-offline-content">
                                <h2 className="title-k">CHUYÊN CẦN</h2>
                                <div
                                  className="gr-k flex-m"
                                  style={{ alignItems: "center" }}
                                >
                                  <p className="flex-1">Danh hiệu:</p>
                                  {showDiligence(data.diligence_medal)}
                                </div>
                                <div className="gr-k flex-m">
                                  <p className="flex-1">Số buổi nghỉ:</p>
                                  <div className="flex-1 text-center">
                                    <span>
                                      {data.number_absent}/{data.total_session}
                                    </span>
                                  </div>
                                </div>
                                <div className="gr-k flex-m">
                                  <p className="flex-1">Số buổi đến muộn:</p>
                                  <div className="flex-1 text-center">
                                    <span>
                                      {data.number_late}/{data.total_session}
                                    </span>
                                  </div>
                                </div>
                                <h2 className="title-k">HỌC TẬP</h2>
                                <div className="gr-k flex-m align-item-center">
                                  <p className="flex-1">Danh hiệu:</p>
                                  <div className="flex-1 text-center badge-student">
                                    <span>
                                      {showAcademic(
                                        data.detail_score[2]?.avg_score
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="gr-k flex-m">
                                  <p className="flex-1">Điểm trung bình:</p>
                                  <div className="flex-1 text-center">
                                    <span>
                                      {typeof data.detail_score[2]
                                        ?.avg_score === "string" &&
                                      data.detail_score[2]?.avg_score === ""
                                        ? "Đang cập nhật"
                                        : data.avg_score}
                                    </span>
                                  </div>
                                </div>
                                <div className="gr-k flex-m mb-30">
                                  <p className="flex-1">Điểm thành phần:</p>
                                  <div className="flex-1 text-center">
                                    <Link
                                      to={
                                        "/" +
                                        authentication.role +
                                        "/assessment/offline/" +
                                        data.id +
                                        "/detail-score"
                                      }
                                    >
                                      <span className="btn">Chi tiết</span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })
                    ) : (
                      <div className="shedu-mo-item flex-center-important border-1-light">
                        <LazyLoad style={{ margin: 20 }}>
                          <img
                            src="/assets/images/student/bg_no_homework.png"
                            alt="bg_no_homework"
                          />
                          <p>Bạn chưa có lớp học Offline nào.</p>
                        </LazyLoad>
                      </div>
                    )}
                  </Slider>
                </ul>
              </LazyLoad>
            ))}
        </div>
      </div>
    </div>
  );
}

export { HomePageOfflineAssessment };
