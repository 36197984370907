import React, { useEffect, useState } from "react";
import { Header } from "../../_components/Admin/Header";
import { useParams, useLocation } from "react-router-dom";
import { studentService } from "../../_services";
import "./style.scss";
import { useHistory } from "react-router-dom";
import { PopUpZoomImageV2 } from "../../_components/Popup/PopUpZoomImageV2";
import $ from "jquery";
import LessonBase from "../../_base/LessonBase";
import DoTestExamModal from "../StudentPage/exam-test/DoTestExam";
import { useSelector } from "react-redux";

const HomePageStudyGuideStudent = () => {
  const [dataGuide, setDataGuide] = useState({});
  const { user_exercise_id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [fileActive, setFileActive] = useState({});
  const [showHideDisplay, setShowHideDisplay] = useState(false);
  const history = useHistory();
  const [htmlWritingContent, setHtmlWritingContent] = useState([]);
  const [openZoomImage, setOpenZoomImage] = useState(false);
  const [examSelected, selectExam] = useState(null);

  const authentication = useSelector((state) => state.authentication);
  const homeworkSelected = useSelector((state) => state.learns.homeworkSelected);

  const location = useLocation();

  const lesson_infor = location?.state?.lesson_infor;
  const from_link = location?.state?.from_link

  const handlepageshow = () => {
    $(".loading").addClass("hide");
  };

  useEffect(() => {
    window.addEventListener("pageshow", handlepageshow);

    return () => {
      window.removeEventListener("pageshow", handlepageshow);
    };
  }, []);

  useEffect(() => {
    let isLoading = !$(".loading").hasClass("hide");
    setLoading(isLoading);
    getData();
  }, []);

  const getData = async () => {
    if (["mini_test", "exam"]?.includes(lesson_infor?.exercise_type)) {
      const res = await studentService.startDoExamService(
        lesson_infor?.id,
        lesson_infor?.exercise_type,
        lesson_infor.class_id,
        lesson_infor.unit,
        true
      );

      if (res.status) {
        setDataGuide(res);
      }
    } else {
      studentService.getDetailStudyGuild(user_exercise_id).then((response) => {
        response && setDataGuide(response);
      });
    }
  };

  const openDetailGuide = (file) => {
    setFileActive(file);
    if (["video", "writing", "audio", "img"].includes(file.type)) {
      setShowHideDisplay(true);
    } else {
      const full_url = dataGuide?.base_url + file?.path;
      window.open(full_url, "_blank");
    }
  };

  function renderIcoFile(data) {
    if (data.type === "document") {
      let arr = data.path.split(".");
      let fileFormat = arr[arr.length - 1];
      if (["doc", "docx"].includes(fileFormat)) {
        return (
          <img
            src={`/assets/images/teacher/giaobai/ico_doc.png`}
            alt={data.type}
          />
        );
      } else if (["xlsx", "xls"].includes(fileFormat)) {
        return (
          <img
            src={`/assets/images/teacher/giaobai/ico_excel.png`}
            alt={data.type}
          />
        );
      } else if (fileFormat === "ppt") {
        return (
          <img
            src={`/assets/images/teacher/giaobai/ico_ppt.png`}
            alt={data.type}
          />
        );
      } else if (fileFormat === "pdf") {
        return (
          <img
            src={`/assets/images/teacher/giaobai/ico_pdf.png`}
            alt={data.type}
          />
        );
      } else {
        return (
          <img
            src={`/assets/images/teacher/giaobai/ico_${data.type}.png`}
            alt={data.type}
          />
        );
      }
    } else {
      return (
        <img
          src={`/assets/images/teacher/giaobai/ico_${data.type}.png`}
          alt={data.type}
        />
      );
    }
  }

  const setIsBack = () => {
    if (showHideDisplay) {
      setShowHideDisplay(false);
      setFileActive({});
    } else {
      history.go(-1);
    }
  };

  const detailImage = () => {
    // window.open(dataGuide.base_url + fileActive?.path);
    setOpenZoomImage(true);
  };

  const goToHomeWork = () => {
    if (
      ["exam", "mini_test"].includes(
        homeworkSelected?.exercise_type || homeworkSelected?.lesson_type
      )
    ) {
      selectExam(homeworkSelected);
      return;
    } else {
      $(".loading").removeClass("hide");
      LessonBase._moveLesson(
        LessonBase.Type.homework,
        authentication,
        homeworkSelected,
        null,
        3,
        from_link
      );
    }
  };

  useEffect(() => {
    if (fileActive.type == "writing") {
      const htmlFileContent = fileActive?.content?.split("\n");
      // console.log(htmlFileContent);
      setHtmlWritingContent(htmlFileContent);
    }
  }, [fileActive.type]);

  return (
    <div className="sunE-right-container flex-column">
      <Header title={"Hướng dẫn học tập"} isBack clickBack={setIsBack} />
      <div className="sunE-container-box giaobai-support huong-dan-hoc-tap flex-1" style={{position: 'relative'}}>
        {!isLoading && (
          <>
            <div className="sunE-giaotrinh-list scrollbar-custom h-giaobai-support-list content_study_guide_container">
              {!showHideDisplay &&
                dataGuide.list_guide_file &&
                dataGuide.list_guide_file.map((item, idx) => {
                  return (
                    <div
                      className="box-giaotrinh-gr rel box-shadow hei-custom flex-m"
                      key={idx}
                      onClick={() => openDetailGuide(item)}
                    >
                      <div className="flex-m" style={{ flex: 1 }}>
                        <div className="thumb2">{renderIcoFile(item)}</div>
                        <div className="box-select-info flex-1">
                          <p className="semibold text-left one-line">
                            {item?.title}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {!showHideDisplay &&
                dataGuide.list_guide_file &&
                dataGuide?.list_guide_file.length === 0 && (
                  <span className="__text_empty_tutorial_guide">
                    Bài tập này chưa có hướng dẫn học tập
                  </span>
                )}
              {showHideDisplay && (
                <div>
                  <div className="box-giaotrinh-gr rel box-shadow hei-custom">
                    <div className="flex-m" style={{ flex: 1 }}>
                      <div className="thumb2">{renderIcoFile(fileActive)}</div>
                      <div className="box-select-info flex-1">
                        <p className="semibold text-left one-line">
                          {fileActive?.title}
                        </p>
                      </div>
                    </div>
                  </div>
                  {fileActive?.type === "writing" && (
                    <div className="box-content-writing-study-guide rel box-shadow hei-custom">
                      {htmlWritingContent.map((item, index) => (
                        <div key={index}>{item}</div>
                      ))}
                    </div>
                  )}
                  {fileActive?.type === "video" && (
                    <div className="box-content-study-guide rel hei-custom">
                      <video controls="controls" className="content-study-guide">
                        <source
                          src={dataGuide.base_url + fileActive?.path}
                          alt={fileActive?.type}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  )}
                  {fileActive?.type === "audio" && (
                    <div className="box-content-study-guide rel hei-custom">
                      <audio controls="controls">
                        <source
                          src={dataGuide.base_url + fileActive?.path}
                          alt={fileActive?.type}
                          type="audio/mpeg"
                        />
                      </audio>
                    </div>
                  )}
                  {fileActive?.type === "img" && (
                    <div className="pos_rel box-content-study-guide rel hei-custom">
                      <div className="pos_rel">
                        <img
                          className="content-study-guide"
                          src={dataGuide.base_url + fileActive?.path}
                          alt={fileActive?.type}
                        />
                        <img
                          onClick={detailImage}
                          className="contain_image pos_abs ico_zoom_out"
                          src="/assets/images/icon/ico_zoom_out.png"
                          alt="Icon Zoom out"
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            {homeworkSelected && <button onClick={goToHomeWork} className="btn-line-blue" style={{
              position: 'absolute',
              bottom: '32px',
              left: '50%',
              transform: 'translateX(-50%)'
            }}>Làm bài</button>}
          </>
        )}
      </div>

      {examSelected && (
        <DoTestExamModal
          setExamSelected={selectExam}
          isShow={examSelected !== null}
          classId={examSelected.class_id}
          unitId={examSelected.unit}
          isHomeWork={true}
          onClose={() => {
            selectExam(null);
            // fetchDataGetHomeWorkByTeacher();
          }}
          mockTestId={
            examSelected?.id ||
            examSelected?.lesson_id ||
            examSelected?.exercise_id ||
            examSelected?.exam_id
          }
          type={examSelected.lesson_type || examSelected.exercise_type}
          heading={examSelected?.exam_name || examSelected?.name || examSelected?.lesson_name || examSelected?.exercise_name || ''}
        />
      )}
      {openZoomImage && (
        <PopUpZoomImageV2
          src={dataGuide.base_url + fileActive?.path}
          height="90%"
          onClickNo={() => setOpenZoomImage(false)}
        />
      )}
    </div>
  );
};

export { HomePageStudyGuideStudent };
