import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { DropDownCustom } from "../../../_components/dropdown";
import { studentService } from "../../../_services/students";
import {
  getListClassService,
  getLocationService,
} from "../../../_services/user";
import DoTestExamModal from "./DoTestExam";
import { configConstants, studentConstants } from "../../../_constants";
import "./styles.scss";
import moment from "moment";

const TABS_VIEW = [
  {
    id: "mock_15",
    title: "Kiểm tra 15 phút",
  },
  {
    id: "mock_45",
    title: "Kiểm tra 45 phút",
  },
  {
    id: "mock_60",
    title: "Kiểm tra học kỳ",
  },
  {
    id: "mock_class_10",
    title: "Luyện thi vào lớp 10",
  },
];

const PAGE_SIZE = 10;

const ExamTestPage = (props) => {
  const { mockTestID, type } = useParams();

  // console.log(type);

  const [tabSelected, selectTabView] = useState(TABS_VIEW[0].id);
  const [listClass, setListClass] = useState([]);
  const [classSelected, selectClass] = useState();
  const [listLocation, setListLocation] = useState([]);
  const [locationSelected, setLocation] = useState();
  const [listDataView, setListDataView] = useState([]);
  const [mockTestSelected, selectMockTest] = useState(mockTestID);
  const [isLoading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [isLoadMore, setLoadMore] = useState(true);
  const [isLoadingScroll, setLoadingScroll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [isLoadingNewTab, setIsLoadingNewTab] = useState(false)

  const history = useHistory();
  const authentication = useSelector((state) => state.authentication);
  const examTest = useSelector((state) => state.examTest);
  const grades = useSelector((state) => state.grades);
  const dispatch = useDispatch();

  const listDataRef = useRef(null)

  function hasDuplicates(array1, array2) {
    var combinedArray = array1.concat(array2);
    return new Set(combinedArray).size !== combinedArray.length;
  }

  useEffect(() => {
    if (grades.listAddress) {
      let list = grades.listAddress.map((item) => {
        item.value = item.id;
        item.title = item.address;
        return item;
      });
      list = [{ value: null, title: "Chọn tỉnh/TP", id: null }, ...list];
      setLocation(list[0]);
      setListLocation(list);
    }
    if (grades.listGrade) {
      let list = grades.listGrade.map((item) => {
        item.value = item.id;
        item.title = item.name;
        return item;
      });
      list = [{ value: null, title: "Chọn khối", id: null }, ...list];
      selectClass(list[0]);
      setListClass(list);
    }
    // setCurrentPage(0);
  }, [grades.listAddress, grades.listGrade]);

  useEffect(() => {
    let isGetData = true;
    switch (tabSelected) {
      case "mock_15":
        if (!!examTest?.exam15?.data?.length && !!examTest?.exam15?.timeGetData &&
          moment(new Date()).diff(moment(examTest?.exam15?.timeGetData), 'hours') < 168
        ) {
          isGetData = false;
          setListDataView(examTest?.exam15?.data);
          setCurrentPage(examTest?.exam15?.offset)
        }
        break;
      case "mock_45":
        if (!!examTest?.exam45?.data?.length && !!examTest?.exam45?.timeGetData &&
          moment(new Date()).diff(moment(examTest?.exam45?.timeGetData), 'hours') < 168
        ) {
          isGetData = false;
          setListDataView(examTest?.exam45?.data);
          setCurrentPage(examTest?.exam45?.offset)
        }
        break;
      case "mock_60":
        if (!!examTest?.exam60?.data?.length && !!examTest?.exam60?.timeGetData &&
          moment(new Date()).diff(moment(examTest?.exam60?.timeGetData), 'hours') < 168
        ) {
          isGetData = false;
          setListDataView(examTest?.exam60?.data);
          setCurrentPage(examTest?.exam60?.offset)
        }
        break;
      case "mock_class_10":
        if (!!examTest?.examClass10?.data?.length && !!examTest?.examClass10?.timeGetData &&
          moment(new Date()).diff(moment(examTest?.examClass10?.timeGetData), 'hours') < 168
        ) {
          isGetData = false;
          setListDataView(examTest?.examClass10?.data);
          setCurrentPage(examTest?.examClass10?.offset)
        }
        break;
      default:
        break;
    }

    if (isGetData) {
      getListDataView(currentPage);
    } else if (!!classSelected?.value || !!locationSelected?.value) {
      getListDataView(0)
    } else {
      setIsLoadingNewTab(false);
    }
  }, [tabSelected]);

  useEffect(() => {
    if (isFilter) {
      let isGetData = true;
      switch (tabSelected) {
        case "mock_15":
          console.log('aaaa: ' , moment(new Date()).diff(moment(examTest?.exam15?.timeGetData), 'hours'))
          if (!!examTest?.exam15?.data?.length && !!examTest?.exam15?.timeGetData &&
            moment(new Date()).diff(moment(examTest?.exam15?.timeGetData), 'hours') < 168
          ) {
            isGetData = false;
            setListDataView(examTest?.exam15?.data);
            setCurrentPage(examTest?.exam15?.offset)
          }
          break;
        case "mock_45":
          if (!!examTest?.exam45?.data?.length && !!examTest?.exam45?.timeGetData &&
            moment(new Date()).diff(moment(examTest?.exam45?.timeGetData), 'hours') < 168
          ) {
            isGetData = false;
            setListDataView(examTest?.exam45?.data);
            setCurrentPage(examTest?.exam45?.offset)
          }
          break;
        case "mock_60":
          if (!!examTest?.exam60?.data?.length && !!examTest?.exam60?.timeGetData &&
            moment(new Date()).diff(moment(examTest?.exam60?.timeGetData), 'hours') < 168
          ) {
            isGetData = false;
            setListDataView(examTest?.exam60?.data);
            setCurrentPage(examTest?.exam60?.offset)
          }
          break;
        case "mock_class_10":
          if (!!examTest?.examClass10?.data?.length && !!examTest?.examClass10?.timeGetData &&
            moment(new Date()).diff(moment(examTest?.examClass10?.timeGetData), 'hours') < 168
          ) {
            isGetData = false;
            setListDataView(examTest?.examClass10?.data);
            setCurrentPage(examTest?.examClass10?.offset)
          }
          break;
        default:
          break;
      }

       if (!!classSelected?.value || !!locationSelected?.value) {
        getListDataView(0)
      } else if (isGetData) {
        getListDataView(currentPage);
      } else {
        setIsFilter(false)
      }
    }
  }, [isFilter])

  const getListDataView = async (offset) => {
    try {
      setLoading(true);
      let params = {
        purpose: tabSelected,
        limit: configConstants.DEFAULT_LIMIT,
        offset: offset || 0,
      };
      if (classSelected?.id) {
        params.gradeId = classSelected?.id;
      }
      if (locationSelected?.id) {
        params.addressId = locationSelected?.id;
      }

      const res = await studentService.getExamTestService(params);
      setLoading(false);
      setIsLoadingNewTab(false);
      if (res?.status) {
        if (
          !hasDuplicates(listDataView, res?.list_mock_test) 
        ) {
          setLoadingScroll(false);
          setListDataView(res?.list_mock_test || []);
          setCurrentPage(offset);
          handleSaveData(tabSelected, res?.list_mock_test || [], offset)
        }
      }
    } catch (e) {
      console.log('eee: ', e)
    }
  };

  const getListDataViewScroll = async (offset) => {
    try {
      setLoading(true);
      let params = {
        purpose: tabSelected,
        limit: configConstants.DEFAULT_LIMIT,
        offset: offset,
      };
      if (classSelected.id) {
        params.gradeId = classSelected.id;
      }
      if (locationSelected.id) {
        params.addressId = locationSelected.id;
      }

      if ((currentPage != offset || offset == 0) && !isLoadingScroll && !isLoading) {
        setLoadingScroll(true);
        const res = await studentService.getExamTestService(params);
        setLoading(false);
        if (res?.status) {
          if (
            !hasDuplicates(listDataView, res?.list_mock_test) &&
            res.list_mock_test.length > 0
          ) {
            setLoadingScroll(false);
            setListDataView((prev) => [...prev, ...res?.list_mock_test]);
            setCurrentPage(offset);
            handleSaveData(tabSelected, [...listDataView, ...res?.list_mock_test], offset)
          }
        }
      }
    } catch (e) {}
  };

  // Handle Close Do Test
  const handleCloseDoTest = () => {
    if (type == "experience") {
      history.push("/mock_test");
    } else {
      selectMockTest(null);
    }
  };

  // Scroll Mock Test
  const handleScrollMockTests = (e) => {
    if (
      e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 5 &&
      isLoadMore &&
      !isLoadingScroll
    ) {
      getListDataViewScroll(currentPage + configConstants.DEFAULT_LIMIT);
    }
  };

  const handleSaveData = (tabSelected, data, offset) => {
    if (!!classSelected?.value || !!locationSelected?.value || !!isFilter) {
      setIsFilter(false);
      return;
    }
    setIsFilter(false);
    switch (tabSelected) {
      case "mock_15":
        dispatch({
          type: studentConstants.GET_EXAM_15,
          payload: {
            data,
            offset
          }
        })
        break;
      case "mock_45":
        dispatch({
          type: studentConstants.GET_EXAM_45,
          payload: {
            data,
            offset
          }
        })
        break;
      case "mock_60":
        dispatch({
          type: studentConstants.GET_EXAM_60,
          payload: {
            data,
            offset
          }
        })
        break;
      case "mock_class_10":
        dispatch({
          type: studentConstants.GET_EXAM_CLASS_10,
          payload: {
            data,
            offset
          }
        })
        break;
      default:
        break;
    }
  }

  const handleReload = () => {
    listDataRef.current?.scrollTo(0, 0);
    getListDataView(0);
  }

  return (
    <div id="p-exam-test">
      <div className="header-view">
        <div className="title-header">Luyện Thi</div>
        <div className="filter">
          {(!classSelected?.value && !locationSelected?.value) && <div className="refresh-curriculum-btn-teacher-header" style={{width: 40, height: 40, marginRight: 8}} onClick={handleReload}>
            <img src="/assets/images/icon_refresh_curriculum.png" style={{width: '100%', height: '100%', borderRadius: '50%'}}/>
          </div>}
          <DropDownCustom
            listOptions={listClass}
            itemSelected={classSelected}
            onChangeValue={(item) => {
              selectClass(item);
              setCurrentPage(0);
              setIsFilter(true);
              listDataRef.current?.scrollTo(0, 0);
            }}
          />
          <DropDownCustom
            classItem="with-200"
            listOptions={listLocation}
            itemSelected={locationSelected}
            onChangeValue={(item) => {
              setLocation(item);
              setCurrentPage(0);
              setIsFilter(true);
              listDataRef.current?.scrollTo(0, 0);
            }}
          />
          <button
            className="btn btn-history"
            onClick={() =>
              history.push("/" + authentication.role + "/exam-test/history")
            }
          >
            <img src="/assets/images/luyenthi/ico-history.png" />
            <span>Lịch sử làm bài</span>
          </button>
        </div>
      </div>
      <div className="body-view">
        <div className="tab-view">
          {TABS_VIEW.map((tab) => (
            <div
              className={`btn-gr flex-m tab-item${
                tab.id === tabSelected ? " active" : ""
              }`}
              key={tab.id}
              onClick={() => {
                listDataRef.current?.scrollTo(0, 0);
                selectTabView(tab.id);
                setCurrentPage(0);
                setIsLoadingNewTab(true);
              }}
            >
              <div className="flex-1 btn-gr-text text-center">
                <p>{tab.title}</p>
              </div>
            </div>
          ))}
        </div>
        <div
          onScroll={handleScrollMockTests}
          className="list-view scrollbar-custom"
          ref={listDataRef}
        >
          {!isLoadingNewTab && listDataView.map((item) => (
            <div
              className="unit-test"
              key={item.id}
              onClick={() => selectMockTest(item)}
            >
              <img src="/assets/images/luyenthi/ico-unit-test.png" />
              <div className="content-view">
                <div className="title">
                  {item.name} |{" "}
                  {TABS_VIEW.filter((tab) => tab.id === item.purpose)[0]?.title}
                </div>
                <div className="description">
                  {item.school} - {item.address}{" "}
                </div>
                <div className="time-date">Năm học: {item.school_year}</div>
              </div>
            </div>
          ))}
          {!isLoading && (!listDataView || listDataView.length === 0) ? (
            <div className="sunE-no-class text-center flex-1">
              <img
                style={{ width: "80%", marginTop: -50 }}
                src="/assets/images/exam_empty.png"
                alt="img_no_class_big"
              />
              <p style={{ marginTop: -50 }} className="text-light">
                Đề thi đang được cập nhật.
              </p>
            </div>
          ) : null}
        </div>
      </div>
      {mockTestSelected && window.location.href.indexOf("history") <= -1 && (
        <DoTestExamModal
          setExamSelected={selectMockTest}
          isShow={mockTestSelected !== null}
          onClose={handleCloseDoTest}
          mockTestId={mockTestID || mockTestSelected?.id}
          type={"mock_test"}
          typeMockTest={type}
        />
      )}
    </div>
  );
};

export default React.memo(ExamTestPage);
