import { studentConstants } from "../../_constants";
import { userConstants } from "../../_constants";
var initialState = {
  online: {
    number_vocab: "",
    number_medal: "",
    list_medal: [],
    data_assessment: {
      grammar_score: 0,
      reading_score: 0,
      speaking_score: 0,
      listening_score: 0,
      writing_score: 0,
      test_score: 0,
      grammar_score_change: 0,
      reading_score_change: 0,
      speaking_score_change: 0,
      listening_score_change: 0,
      writing_score_change: 0,
      test_score_change: 0,
      total_diamond: 0,
    },
  },
  learning_by_date: {
    data: [],
  },
  logLearnings: {
    data: {
      recent_activity: [],
    },
  },
  dataLearningHistory: {
    day: null,
    week: null,
    month: null
  },
  dataAssessmentOffline: null,
  dataAssessmentRanking: null,
  offsetAssessmentRanking: 0,
  timeGetData: {
    assessmentOnline: null,
    assessmentOffline: null,
    assessmentRanking: null,
  },
};

const assessments = (state = initialState, action) => {
  switch (action.type) {
    case studentConstants.GET_ASSESSMENT_RANKING:
      return {
        ...state,
        dataAssessmentRanking: action.payload.data,
        offsetAssessmentRanking: action.payload.offset,
      };
    case studentConstants.GET_ASSESSMENT_OFFLINE:
      return {
        ...state,
        dataAssessmentOffline: action.payload
      };
    case studentConstants.GET_DATA_LEARNING_HISTORY_ASSESSMENT:
      return {
        ...state,
        dataLearningHistory: {
          ...state?.dataLearningHistory,
          [action.payload.type]: action.payload.data
        }
      };
    case studentConstants.GET_TIME_ASSESSMENT_ONLINE:
      return {
        ...state,
        timeGetData: {
          ...state.timeGetData,
          assessmentOnline: action?.payload
        },
      };
    case studentConstants.GET_TIME_ASSESSMENT_OFFLINE:
      return {
        ...state,
        timeGetData: {
          ...state.timeGetData,
          assessmentOffline: action?.payload
        },
      };
    case studentConstants.GET_TIME_ASSESSMENT_RANKING:
      return {
        ...state,
        timeGetData: {
          ...state.timeGetData,
          assessmentRanking: action?.payload
        },
      };
    case studentConstants.GET_ASSESSMENT_ONLINE:
      return {
        ...state,
        online: action.assessments,
      };
    case studentConstants.GET_LOG_LEARNING_BY_DATE:
      return {
        ...state,
        learning_by_date: action.learnings,
      };
    case studentConstants.GET_LIST_LOG_LEARNING:
      return {
        ...state,
        logLearnings: action.learnings,
      };
    case userConstants.RESET_ALL_STATE:
      return initialState;
    default:
      return state;
  }
};

export { assessments };
