import { useState } from "react";
import { isEmail } from "../../../_helpers/validateEmail";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../../_actions";
import { userConstants } from "../../../_constants";
import { useHistory } from "react-router-dom";
import { validateEmail } from "../../../_base/Validate";
import { userActions } from "../../../_actions";
import { configConstants } from "../../../_constants";
import { useEffect } from "react";
import { apiCaller } from "../../../_helpers";
import { history } from "../../../_helpers";

export const loginNewsLogic = (props) => {
  const alert = useSelector((state) => state.alert);
  const _dispatch = useDispatch();
  const _history = useHistory();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [emailError, setEmailError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [disabledBtnLogin, setDisabledBtnLogin] = useState(true);
  const [msgResent, setMsgResent] = useState({
    loading: false,
    msg: "Gửi lại email kích hoạt khác",
  });

  useEffect(() => {
    if (email && validateEmail(email) && password && password.length >= 6) {
      setDisabledBtnLogin(false);
    } else {
      setDisabledBtnLogin(true);
    }
  }, [email, password]);

  // Variable device login
  const getDevice = () => {
    let currentBrowser = "Unknown";
    if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
      currentBrowser = "Google_Chrome";
    } else if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
      currentBrowser = "Mozilla_Firefox";
    } else if (window.navigator.userAgent.indexOf("MSIE") !== -1) {
      currentBrowser = "Internet_Exployer";
    } else if (window.navigator.userAgent.indexOf("Edge") !== -1) {
      currentBrowser = "Edge";
    } else if (window.navigator.userAgent.indexOf("Safari") !== -1) {
      currentBrowser = "Safari";
    } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
      currentBrowser = "Opera";
    } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
      currentBrowser = "YaBrowser";
    } else {
      return currentBrowser;
    }
    return currentBrowser;
  };

  const makeToken = () => {
    let ID = "";
    let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for (var i = 0; i < 12; i++) {
      ID += characters.charAt(Math.floor(Math.random() * 36));
    }
    return ID;
  };

  const getOS = () => {
    const userAgent = navigator.userAgent;
    if (userAgent.includes('Win')) return 'Windows web';
    if (userAgent.includes('Mac')) return 'MacOS web';
    if (userAgent.includes('Linux')) return 'Linux web';
    if (userAgent.includes('Android')) return 'Android web';
    if (userAgent.includes('like Mac')) return 'iOS web';
    return 'unknown web';
  };

  const [inputs, _setInputs] = useState({
    device_id: makeToken(),
    device_name: getDevice(),
    fcm_token: makeToken(),
    os: getOS(),
    DeviceType: 'web'
  });

  const onBlurField = (type) => {
    switch (type) {
      case "email": {
        if (email && !emailError && !validateEmail(email)) {
          setEmailError("Định dạng email không đúng");
        } else if (validateEmail(email) || email == "") {
          setEmailError("");
        }

        return;
      }
      default: {
        // if (password?.trim() === "" && !passwordError) {
        //   setPasswordError(
        //     "Mật khẩu cần tối thiểu 6 kí tự và không gồm toàn dấu cách"
        //   );
        // }
        // if (password && !passwordError && password.length < 6) {
        //   setPasswordError("Mật khẩu phải có từ 6 ký tự trở lên");
        // }
        // if (password == "") {
        //   setPasswordError("");
        // }
        // return;
      }
    }
  };

  const changeEmail = (value) => {
    _dispatch(alertActions.clear());
    if (alert.message && alert.screen === userConstants.SCREEN_LOGIN) {
      _dispatch(alertActions.clear());
    }
    if (emailError) {
      if (validateEmail(value) || value == "") {
        setEmailError("");
      }
    }
    setEmail(value?.trim());
  };

  const changePassword = (value) => {
    _dispatch(alertActions.clear());
    setPasswordError("");
    if (alert.message && alert.screen === userConstants.SCREEN_LOGIN) {
      _dispatch(alertActions.clear());
    }
    if (passwordError) {
      if (value.length >= 6 || value == "") {
        setPasswordError("");
      }
    }
    setPassword(value);
  };

  const handleNavigateAuth = (type) => {
    _history.push(`/${type}`);
  };

  const handleLogin = (e) => {
    e?.preventDefault();
    // setSubmitted(true);
    const dataLogin = {
      ...inputs,
      username: email,
      password: password,
    };
    _dispatch(
      userActions.login(dataLogin, configConstants.LOGIN_TYPE_DEFAULT, "news")
    );
  };

  const handleResentEmail = () => {
    if (!msgResent.loading) {
      setMsgResent({
        loading: true,
        msg: "Đang gửi...",
      });
      apiCaller(`/api_register/resend_active_email?email=${email}`)
        .then((res) => {
          if (res.status) {
            setMsgResent({
              loading: true,
              msg: "Gửi email xác nhận thành công",
            });
            // setTimeout(() => {
            //   setMsgResent({
            //     loading: false,
            //     msg: "Gửi lại email kích hoạt khác",
            //   });
            // }, 5000);
          } else {
            setMsgResent({
              loading: true,
              msg: "Gửi email xác nhận thất bại",
            });
            setTimeout(() => {
              setMsgResent({
                loading: false,
                msg: "Gửi lại email kích hoạt khác",
              });
            }, 3000);
          }
        })
        .catch((e) => {
          setMsgResent({
            loading: true,
            msg: "Gửi email xác nhận thất bại",
          });
          setTimeout(() => {
            setMsgResent({
              loading: false,
              msg: "Gửi lại email kích hoạt khác",
            });
          }, 3000);
        });
    }
  };

  const handleCloseModalResent = () => {
    _dispatch(alertActions.clear());
    setMsgResent({
      loading: false,
      msg: "Gửi lại email kích hoạt khác",
    });
  };

  // Handle Login Google
  const [loginType, setLoginType] = useState({
    type: "default",
    step: "normal_login",
  });
  const [dataStepEmail, setDataStepEmail] = useState({
    email: "",
  });

  const [dataUpdateInfo, setDataUpdateInfo] = useState({
    fullname: "",
    birthday: "",
    gender: "male",
    phone: "",
    province: "",
    district: "",
    school: "",
    onRegister: false,
  });

  const onLoginGoogleSuccess = (user) => {
    // alert("Waiting Api");
    // setDataStepEmail({
    //   email: user.profileObj?.email ?? "",
    // });
    const dataLoginGoogle = {
      email: user.profileObj?.email ?? "",
      fullname: user.profileObj?.name ?? "",
      id_token: user.tokenId,
      access_token: user.accessToken,
      organization_id: 1,
    };
    localStorage.setItem("data_google", JSON.stringify(dataLoginGoogle));
    // setDataUpdateInfo({
    //   ...dataUpdateInfo,
    //   fullname: user.profileObj?.name ?? "",
    // });
    const data = {
      id_token: user.tokenId,
      access_token: user.accessToken,
      organization_id: 1,
    };
    // setCacheTokenSocial(data);
    _dispatch(userActions.login(data, configConstants.LOGIN_VIA_GOOLE));
    // history.push("/register_news/google");
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return {
    email,
    password,
    emailError,
    passwordError,
    disabledBtnLogin,
    msgResent,
    setMsgResent,
    onBlurField,
    setEmail,
    setPassword,
    setEmailError,
    setPasswordError,
    changeEmail,
    changePassword,
    handleLogin,
    handleNavigateAuth,
    handleResentEmail,
    handleCloseModalResent,
    onLoginGoogleSuccess,
  };
};
