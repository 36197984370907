import { curriculumConstants } from "../../_constants";
import { userConstants } from "../../_constants";
var initialState = {
  dataCurriculum: null,
  timeGetDataCurriculum: null
};
if (localStorage.getItem("persistCurriculumTeacher")) {
  initialState = JSON.parse(localStorage.getItem("persistCurriculumTeacher"));
}

const persistCurriculumTeacher = (state = initialState, action) => {
  switch (action.type) {
    case curriculumConstants.SAVE_DATA_CURRICULUM_TEACHER: {
      const newState = {
        ...state,
        dataCurriculum: action?.payload,
        timeGetDataCurriculum: new Date()
      }
      localStorage.setItem(
        "persistCurriculumTeacher",
        JSON.stringify(newState)
      );
      return newState;
    }
    case curriculumConstants.SAVE_DETAIL_CURRICULUM_TEACHER: {
      const newState = {
        ...state,
        dataCurriculum: {
          ...state?.dataCurriculum,
          courses: state?.dataCurriculum?.courses?.map(item => {
            if (item?.id == action?.id) {
              return {
                ...item,
                data_map: action.data_map
              }
            }
            return item;
          })
        }
      }
      localStorage.setItem(
        "persistCurriculumTeacher",
        JSON.stringify(newState)
      );
      return newState;
    }
    case userConstants.RESET_ALL_STATE:
      return initialState;
    default:
      return state;
  }
};

export { persistCurriculumTeacher };
