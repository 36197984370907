import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { teacherConstants } from "./../../../_constants";
import { teacherActions } from "../../../_actions";
import { Header } from "../../../_components/Admin/Header";
import { Alert } from "../../../_components/Alert";

function ReviewStudent() {
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const classes = useSelector((state) => state.classes);
  const [selectAll, setSelectAll] = useState(false);

  const [listRequestIdAll, setListRequestIdAll] = useState([]);

  useEffect(() => {
    if (!classes?.requests?.list_request?.length) {
      dispatch(teacherActions.getRequestStudent(id));
    }
  }, []);

  function onChangeSelectAll() {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setListRequestIdAll(classes.requests.list_request.map((obj) => obj.id));
      classes.requests.list_request.forEach(item => {item.checked = true});
    } else {
      classes.requests.list_request.forEach(item => {item.checked = false});
      setListRequestIdAll([]);
    }
  }

  function removeStudenntId(id) {
    const index = listRequestIdAll.indexOf(id);
    if (index > -1) {
      listRequestIdAll.splice(index, 1);
      setListRequestIdAll([...listRequestIdAll]);
    }
  }

  function accpetRequest(list_request_id = listRequestIdAll) {
    let final = false;
    if (classes.requests.list_request.length === list_request_id?.length) {
      final = true;
    }
    if (validateParam()) {
      dispatch(
        teacherActions.acceptRequestStudent(
          {
            class_id: id,
            list_request_id,
          },
          final
        )
      );
      if (list_request_id.length === 1) {
        removeStudenntId(list_request_id[0]);
      }
      // if (classes.requests.list_request?.length === list_request_id?.length) {
      //   history.push(`/teacher/class/view/${id}/student`);
      // }
      // history.push(`/teacher/class/view/${id}/student`);
    }
  }

  function rejectRequest(list_request_id = listRequestIdAll) {
    let final = false;
    if (classes.requests.list_request.length === list_request_id?.length) {
      final = true;
    }
    if (validateParam()) {
      dispatch(
        teacherActions.rejectRequestStudent(
          {
            class_id: id,
            list_request_id,
          },
          final
        )
      );
      if (list_request_id.length === 1) {
        removeStudenntId(list_request_id[0]);
      }
      // if (classes.requests.list_request?.length === list_request_id?.length) {
      //   history.push(`/teacher/class/view/${id}/student`);
      // }
    }
  }

  function hanldeChecked(id) {
    if (selectAll) {
      setSelectAll(false);
    }
    if (!listRequestIdAll.includes(id)) {
      listRequestIdAll.push(id);
    } else {
      const index = listRequestIdAll.indexOf(id);
      if (index > -1) {
        listRequestIdAll.splice(index, 1);
      }
    }
    classes.requests.list_request.forEach(item => {
      if (listRequestIdAll.includes(item.id)) {
        item.checked = true
      } else {
        item.checked = false
      }
    })
    setListRequestIdAll([...listRequestIdAll]);
    if (listRequestIdAll.length === classes.requests.list_request.length) {
      setSelectAll(true);
    }
  }

  function validateParam() {
    return listRequestIdAll.length > 0 ? true : false;
  }

  return (
    <div className="sunE-right-container add_student_container flex-column">
      <Header title={"Đăng ký vào lớp"} isBack />
      <div className="class_student_list dk_class flex-1">
        <div className="select-all">
          <span className="chk-custom-gr wh20">
            <input
              type="checkbox"
              name="all"
              id="all"
              checked={selectAll ? true : false}
              onClick={onChangeSelectAll}
            />
            <label htmlFor="all">
              {/* {selectAll ? "Bỏ " : ""}  */}
              Chọn tất cả</label>
          </span>
        </div>
        {alert.message &&
          alert.screen === teacherConstants.SCREEN_REVIEW_STUDENT && (
            <Alert alert={alert} />
          )}
        <div className="box-shadow custom-list-student class_student_list_box review scrollbar-custom">
          {classes.requests.list_request.map((data, i) => {
            return (
              <div className="list-student" key={i}>
                <div
                  className="item-student flex-m"
                  style={{ alignItems: "center" }}
                >
                  <span className="stt">{i + 1}</span>
                  <div className="item-student-img">
                    <img
                      src={classes.requests.base_url + "/" + data.avatar}
                      alt="avatar"
                    />
                  </div>
                  <div className="flex-1 item-student-name">
                    <p className="one-line">{data.fullname}</p>
                  </div>
                  <div className="">
                    <span className="chk-custom-gr wh20">
                      <input
                        type="checkbox"
                        name="pnv"
                        id={data.id}
                        checked={data?.checked || false}
                        onChange={() => hanldeChecked(data.id)}
                      />
                      <label htmlFor={data.id}></label>
                    </span>
                    {/* <button className="btn-accept" onClick={() => accpetRequest([data.id])}>Chấp nhận</button>
                                        <button className="btn-reject" onClick={() => rejectRequest([data.id])}>Từ chối</button> */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="btn-accept-reject-select-gr text-right btn-mr-custom">
        <button
          className={
            "btn-select-custom btn-reject" +
            (!validateParam() ? " btn-disable" : "")
          }
          onClick={() => rejectRequest()}
        >
          Từ chối
        </button>
        <button
          className={
            "btn-select-custom btn-reject" +
            (!validateParam() ? " btn-disable" : "")
          }
          onClick={() => accpetRequest()}
        >
          Duyệt
        </button>
      </div>
    </div>
  );
}

export { ReviewStudent };
