import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import { ChartDoughnut } from "./../../Chart";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { configConstants } from "../../../_constants";
import { homeType } from "../../../_constants";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import $ from "jquery";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div onClick={onClick} className={className}>
      <img
        src='/assets/images/icon/ico_right_white.png'
        alt='ico_right_white'
      />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div onClick={onClick} className={className}>
      <img src='/assets/images/icon/ico_left_white.png' alt='ico_left_white' />
    </div>
  );
}

function SliderHomePage(props) {
  const dispatch = useDispatch();
  const learns = useSelector((state) => state.learns);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0); // State to track the current slide index
  const sliderRef = useRef(null); // Ref for Slider component
  const itemActiveHomeStudent = useSelector(
    (state) => state.home_page_reducer.itemActiveHomeStudent
  );
  let { dataList, role, getExercises, examSelected, setLoading } = props;
  
  const reRenderSlide = () => {
    if (
      sliderRef &&
      sliderRef.current &&
      dataList?.length > 0 &&
      !examSelected
    ) {
      if (itemActiveHomeStudent && itemActiveHomeStudent.type) {
        const checkTypeIndex = (item) => {
          return itemActiveHomeStudent.type == "curriculum"
            ? (''+item?.curriculum_id).trim()
            : (''+item?.id).trim();
        };

        const index = dataList.findIndex(
          (item) => checkTypeIndex(item) == (''+itemActiveHomeStudent?.id).trim()
        );
        dispatch({
          type: homeType.SELECT_TEACHER_OR_CURRICULUM,
          payload: {
            type: itemActiveHomeStudent?.type,
            id: itemActiveHomeStudent?.id,
            scrollTop: 0,
          },
        });

        setCurrentSlideIndex(index);
        sliderRef.current.slickGoTo(index);
      } else {
        setCurrentSlideIndex(0);
        sliderRef.current.slickGoTo(0);
      }
    }
  };

  // rerender for visible change
  const handleVisibilityChange = () => {
    const currIndex = sliderRef.current.innerSlider.state.currentSlide ?? 0;
    setLoading(true);
    dispatch({
      type: homeType.SELECT_TEACHER_OR_CURRICULUM,
      payload: {
        type: dataList[currIndex]?.type_slide,
        id:
          dataList[currIndex]?.type_slide == "curriculum"
          ? dataList[currIndex]?.curriculum_id
            : dataList[currIndex]?.id,
        scrollTop: 0,
      },
    });
    sliderRef.current.slickGoTo(currIndex);
  };

  useEffect(() => {
    window.addEventListener("pageshow", handleVisibilityChange);
    return () => {
      window.removeEventListener("pageshow", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    // Set the current index when itemActiveHomeStudent changes
    reRenderSlide();
  }, [
    sliderRef,
    itemActiveHomeStudent?.id,
    learns.data.list_teacher,
    learns.data.list_curriculum,
    examSelected,
  ]);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (oldIndex, newIndex) => {
      let currIndex = newIndex ?? 0;
      setCurrentSlideIndex(currIndex);
      dispatch({
        type: homeType.SELECT_TEACHER_OR_CURRICULUM,
        payload: {
          type: dataList[currIndex]?.type_slide,
          id:
            dataList[currIndex]?.type_slide == "curriculum"
            ? dataList[currIndex]?.curriculum_id
              : dataList[currIndex]?.id,
          scrollTop: 0,
        },
      });
      setLoading(true);
      getExercises(
        dataList[currIndex]?.type_slide,
        dataList[currIndex]?.type_slide == "curriculum"
        ? dataList[currIndex]?.curriculum_id
          : dataList[currIndex]?.id
      );
    },
  };

  return (
    <div className='slider-custom-2 slider-custom-student col-min-100'>
      <ul className='uk-slider-items uk-child-width-1-1 uk-child-width-1-1@s uk-child-width-1-1@m rel'>
        <Slider ref={sliderRef} {...settings}>
          {dataList?.map((data, i) => {
            let avatarRes =
              data?.type_slide == "curriculum" ? data?.class_avatar : data?.avatar;

            let AVATAR = isEmpty(data?.avatar)
              ? data?.type_slide == "teacher"
                ? "/assets/images/student/teacher_detail.png"
                : data?.type_slide == "parent" 
                ? `${configConstants.BASE_URL}assets/img_base/parent_male_dfa.jpg`
                : `${configConstants.BASE_URL}assets/img_base/class_dfa.jpg`
                : configConstants.BASE_URL + avatarRes;

            return (
              <li key={i} className='slider_student_wrapper'>
                <div className='box-shadow box-white-content text-center flex-align slider-item-content flex-m'>
                  <div className='img-thumb img-thumb-student responsive_avatar_class_screen_small'>
                    <img
                      className='img-cover responsive_avatar_class_screen_small_img'
                      src={AVATAR}
                      alt='detail2'
                    />
                  </div>
                  <div className='flex-1 slider-item-desc flex-center-column'>
                    <div className='fz-13-75 font_myriad_bold title_content_slider'>
                      {data?.type_slide == "curriculum"
                        ? "Bài giáo trình"
                        : data?.type_slide == 'parent' ? 'Bài tập' : "Bài tập về nhà"}
                    </div>

                    <div className='divider_content_slide'></div>

                    <div className='info_content_slide flex-center-column'>
                      <div className='fz-11-25 color-text'>
                        {data?.type_slide == "teacher" ? "Giáo viên" : data?.type_slide == "parent" ? "Phụ huynh" : "Lớp"}
                      </div>

                      <div className='font_myriad_bold fz-12-5'>
                        {data?.type_slide == "curriculum"
                          ? data?.class_name
                          : data?.fullname}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </Slider>
      </ul>
    </div>
  );
}

export { SliderHomePage };
