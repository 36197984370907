import React, { useEffect, useState } from "react";
import { curriculumActions, teacherActions } from "../../../_actions";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { SelectAsDiv } from "../../../_components/Select";
import { ShowMapGrid, ChangView } from "../../../_components/Admin/Curriculum";
import { teacherService } from "../../../_services";
import { curriculumConstants } from "../../../_constants";
import { isEmpty } from "lodash";
import SelectCurriculum from "../../../_components/Select/SelectCurriculum/SelectCurriculum";
import moment from "moment";

function ViewDetailCurriculum() {
  const { id, type } = useParams();
  const authentication = useSelector((state) => state.authentication);
  const [listCuriculum, setListCurriculum] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const search = history.location.search;
  const params = new URLSearchParams(search);
  const fromPage = params.get("page");
  const typeAccess = params.get("access");
  const subType = params.get("subType");
  const curriculums = useSelector((state) => state.curriculums);
  const dataCurriculumTeacher = useSelector((state) => state.persistCurriculumTeacher);

  useEffect(() => {
    if (type === "personal") {
      teacherActions.getCurriculumV2("personal").then((res) => {
        setListCurriculum(res || []);
      });
    } else {
      if (!!dataCurriculumTeacher?.dataCurriculum?.courses?.length && 
        !!dataCurriculumTeacher?.timeGetDataCurriculum &&
        moment(new Date()).diff(moment(dataCurriculumTeacher?.timeGetDataCurriculum), 'hours') < 24
      ) {
        setListCurriculum(dataCurriculumTeacher?.dataCurriculum?.courses)
        setLoading(false);
      } else {
        getDataCurriculumSundayTeacher()
      }
      
    }
    getData(id, subType)
    return () => {
      setListCurriculum([]);
    };
  }, []);

  const getDataCurriculumSundayTeacher = () => {
    teacherService.getCurriculum("sunday").then((res) => {
      dispatch({
        type: curriculumConstants.SAVE_DATA_CURRICULUM_TEACHER,
        payload: res
      })
      setListCurriculum(res?.courses || []);
    });
  }

  const getData = (id, sub_type) => {
    setLoading(true);
    const dataCurriculum = dataCurriculumTeacher?.dataCurriculum?.courses?.find(item => item?.id == id);
    if (!!dataCurriculum?.data_map) {
      dispatch({
        type: curriculumConstants.GET_DETAIL_CURRICULUM,
        curriculum: dataCurriculum?.data_map,
      });
      setLoading(false);
      return;
    }
    getDetailCurriculum(id, sub_type)
  }

  const getDetailCurriculum = (id, sub_type) => {
    if(!!sub_type) {
      teacherService.getDetailCurriculumPreparationCourse(id).then((res) => {
        if (res) {
          const listData = res?.data
          const curriculum = {
            // ...res,
            data_lesson: {
              data: [...listData],
              unit_name: listData?.map(item => ({
                list_skill: [],
                ...item
              }))
            },
          }
          dispatch({
            type: curriculumConstants.GET_DETAIL_CURRICULUM,
            curriculum,
          });
          dispatch({
            type: curriculumConstants.SAVE_DETAIL_CURRICULUM_TEACHER,
            data_map: {
              ...curriculum,
              data_lesson: {
                unit_name: curriculum?.data_lesson?.unit_name?.map(item => {
                  return {
                    ...item,
                    list_skill: item?.list_skill?.map(skill => ({
                      ...skill,
                      lesson_in_skill: []
                    }))
                  }
                })
              }
            },
            id
          });
        }
        setLoading(false);
      });
    } else {
      curriculumActions.getDataMap(id).then((res) => {
        if (res) {
          const listData = res?.unit_list?.map(item => {
            return {
              ...item,
              stt: !!item?.stt ? `Unit ${item?.stt}` : item?.stt,
              curriculum_id: id,
              curriculum_name: curriculums?.data?.find(item => item?.id == id)?.name
            }
          })
          const curriculum = {
            // ...res,
            data_lesson: {
              data: [...listData],
              unit_name: listData?.map(item => ({
                list_skill: [],
                ...item
              }))
            },
          }
          dispatch({
            type: curriculumConstants.GET_DETAIL_CURRICULUM,
            curriculum,
          });
          dispatch({
            type: curriculumConstants.SAVE_DETAIL_CURRICULUM_TEACHER,
            data_map: {
              ...curriculum,
              data_lesson: {
                unit_name: curriculum?.data_lesson?.unit_name?.map(item => {
                  return {
                    ...item,
                    list_skill: item?.list_skill?.map(skill => ({
                      ...skill,
                      lesson_in_skill: []
                    }))
                  }
                })
              }
            },
            id
          });
        }
        setLoading(false);
      });
    }
  }

  const dispatch = useDispatch();

  function onChangeSelect(key, sub_type) {
    history.push(
      "/" +
        authentication.role +
        "/curriculum/view/detail/" +
        (!!type ? `${type}/` : '') +
        key + '?' +
        (!isEmpty(fromPage) ? `page=${fromPage}` : "") +
        (!!sub_type ? `&subType=${sub_type}` : '')
    );
    getData(key, sub_type)
  }

  const refreshCurriculumSunday = () => {
    getDataCurriculumSundayTeacher();
    getDetailCurriculum(id, subType);
  }

  let curriculumSelected = listCuriculum.find((item) => item.id === id) || {};

  return (
    <div className='sunE-right-container'>
      <div className='sunE-main-title flex-m'>
        <div className='flex-1 input-gr bar-select custom-select-no-bg' style={{marginRight: 20}}>
          <SelectCurriculum
            textDefault={curriculumSelected.name}
            data={listCuriculum}
            onChangeSelect={(key, sub_type) => onChangeSelect(key, sub_type)}
            className='home_curriculum'
            position='left'
            typeName='type'
            typeCurriculum={curriculumSelected?.type}
          />
        </div>
        {!type && <div className="refresh-curriculum-btn-teacher-header" style={{marginRight: 16}} onClick={refreshCurriculumSunday}>
          <img src="/assets/images/icon_refresh_curriculum.png" style={{width: '100%', height: '100%', borderRadius: '50%'}}/>
        </div>}
        <ChangView />
        <div
          className='form-sunE-button rel'
          onClick={() =>
            history.push(
              "/" +
                authentication.role +
                "/curriculum" +
                (type ? "/view/" + type : "/view/sunday") +
                (!isEmpty(fromPage) ? `?page=${fromPage}` : "")
            )
          }
        >
          <button className='btn-line-blue'>Quay lại</button>
        </div>
      </div>
      {!isLoading && curriculumSelected.name && (
        <ShowMapGrid view={curriculums.view} curriculums={curriculums} />
      )}
    </div>
  );
}

export { ViewDetailCurriculum };
