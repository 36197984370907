import { studentConstants } from "../../_constants";
import { userConstants } from "../../_constants";
var initialState = {
  exam15: {
    data: null,
    offset: 0,
    timeGetData: null
  },
  exam45: {
    data: null,
    offset: 0,
    timeGetData: null
  },
  exam60: {
    data: null,
    offset: 0,
    timeGetData: null
  },
  examClass10: {
    data:null,
    offset: 0,
    timeGetData: null
  },
};

const examTest = (state = initialState, action) => {
  switch (action.type) {
    case studentConstants.GET_EXAM_15: 
      return {
        ...state,
        exam15: {
          data: action.payload.data,
          offset: action.payload.offset,
          timeGetData: new Date()
        }
      }
    case studentConstants.GET_EXAM_45: 
      return {
        ...state,
        exam45: {
          data: action.payload.data,
          offset: action.payload.offset,
          timeGetData: new Date()
        }
      }
    case studentConstants.GET_EXAM_60: 
      return {
        ...state,
        exam60: {
          data: action.payload.data,
          offset: action.payload.offset,
          timeGetData: new Date()
        }
      }
    case studentConstants.GET_EXAM_CLASS_10: 
      return {
        ...state,
        examClass10: {
          data: action.payload.data,
          offset: action.payload.offset,
          timeGetData: new Date()
        }
      }
    case userConstants.RESET_ALL_STATE:
      return initialState;
    default:
      return state;
  }
};

export { examTest };
