import { apiCaller, history } from "./../_helpers";
import {
  studentConstants,
  curriculumConstants,
  teacherConstants,
  popupConstants,
  configConstants,
} from "./../_constants";
import { alertActions } from "./";
import $ from "jquery";
import { studentService } from "../_services";
import moment from "moment";

export const studentActions = {
  getAllGrade,
  getExerciseByTeacherId,
  getExerciseByTeacherIdV2,
  getExerciseByCurriculumId,
  getDetailCurriculumById,
  getDataMapCurriculum,
  getDataMapByClassId,
  getDataGridCurriculum,
  getListCurriculum,
  getLessonCurriculum,
  getListClass,
  addParent,
  checkParent,
  applyForClass,
  checkClass,
  getAssessmentsOnline,
  getLogLearningByDate,
  getListLogLearning,
  updateDefaultCurriculum,
  updateDefaultCurriculumFirst,
  getExerciseByParentIdV2,
  getDetailCurriculumPreparationCourse,
  getLessonByTopicStudent
};

function getAllGrade(data) {
  console.log("=====getAllGrade")
  return (dispatch) => {
    return apiCaller("/api_login/grade", "GET", data, null, false).then(
      (grade) => {
        dispatch({
          type: studentConstants.GET_ALL_GRADE,
          grade: grade.list_grade,
        });
      }
    );
  };
}

function getExerciseByTeacherId(teacher_id) {
  return (dispatch) => {
    return apiCaller(
      "/api_student/homework_by_teacher_homepage?teacher_id=" + teacher_id,
      "GET",
      {},
      null,
      false
    ).then((exercises) => {
      dispatch({
        type: studentConstants.GET_LIST_EXERCISE_STUDENT,
        exercises,
      });
    });
  };
}

function getExerciseByTeacherIdV2(teacher_id, learns, isSetCached = false) {
  return (dispatch) => {
    const isMaintenance = !!learns?.dataExerciseTeacher?.[teacher_id]?.timeGetData && moment(new Date()).diff(moment(learns?.dataExerciseTeacher?.[teacher_id]?.timeGetData), 'hours') < 1
    if (isMaintenance) {
      dispatch({
        type: studentConstants.GET_LIST_EXERCISE_STUDENT,
        exercises: learns?.dataExerciseTeacher?.[teacher_id]?.data
      });
      $(".loading-side").addClass("hide");
      return;
    }
    $(".loading-side").removeClass("hide");
    return apiCaller(
      "/api_student/homework_by_teacher?teacher_id=" + teacher_id,
      "GET",
      {},
      null,
      false,
      configConstants.API_URL_SETEST,
      false
    ).then((exercises) => {
      dispatch({
        type: studentConstants.GET_LIST_EXERCISE_STUDENT,
        exercises,
      });
      if (isSetCached) {
        dispatch({
          type: studentConstants.GET_DATA_EXERCISE_TEACHER,
          payload: {
            data: exercises,
            id: teacher_id
          },
        });
      }
      $(".loading-side").addClass("hide");
    });
  };
}

function getExerciseByParentIdV2(parent_id, learns, isSetCached = false) {
  return (dispatch) => {
    const isMaintenance = !!learns?.dataExerciseParent?.[parent_id]?.timeGetData && moment(new Date()).diff(moment(learns?.dataExerciseParent?.[parent_id]?.timeGetData), 'hours') < 1
    if (isMaintenance) {
      dispatch({
        type: studentConstants.GET_LIST_EXERCISE_STUDENT,
        exercises: learns?.dataExerciseParent?.[parent_id]?.data
      });
      $(".loading-side").addClass("hide");
      return;
    }
    $(".loading-side").removeClass("hide");
    return apiCaller(
      "/api_student/homework_by_parent?parent_id=" + parent_id,
      "GET",
      {},
      null,
      false,
      configConstants.API_URL_SETEST,
      false
    ).then((exercises) => {
      dispatch({
        type: studentConstants.GET_LIST_EXERCISE_STUDENT,
        exercises,
      });
      if (isSetCached) {
        dispatch({
          type: studentConstants.GET_DATA_EXERCISE_PARENT,
          payload: {
            data: exercises,
            id: parent_id
          },
        });
      }
      $(".loading-side").addClass("hide");
    });
  };
}

function getExerciseByCurriculumId(curriculum_id, learns, isSetCached = false) {
  console.log('learns: ', learns)
  return (dispatch) => {
    const isMaintenance = !!learns?.dataExerciseCurriculum?.[curriculum_id]?.timeGetData && moment(new Date()).diff(moment(learns?.dataExerciseCurriculum?.[curriculum_id]?.timeGetData), 'hours') < 1
    if (isMaintenance) {
      dispatch({
        type: studentConstants.GET_LIST_EXERCISE_STUDENT,
        exercises: learns?.dataExerciseCurriculum?.[curriculum_id]?.data
      });
      $(".loading-side").addClass("hide");
      return;
    }
    $(".loading-side").removeClass("hide");
    return apiCaller(
      "/api_student/exercise_by_curriculum?curriculum_id=" + curriculum_id,
      "GET",
      {},
      null,
      false,
      configConstants.API_URL_SETEST,
      false
    ).then((exercises) => {
      const modifiedExercises = {
        ...exercises,
        list_home_work: exercises.list_home_work?.map((homework) => ({
          ...homework,
          curriculum_id: curriculum_id,
        })),
      };
      dispatch({
        type: studentConstants.GET_LIST_EXERCISE_STUDENT,
        exercises: modifiedExercises,
      });
      if (isSetCached) {
        dispatch({
          type: studentConstants.GET_DATA_EXERCISE_CURRICULUM,
          payload: {
            data: modifiedExercises,
            id: curriculum_id
          },
        });
      }
      $(".loading-side").addClass("hide");
    });
  };
}

function getDetailCurriculumById(id) {
  return (dispatch) => {
    return apiCaller(
      "/student/api_student_curriculum/map_unit?curriculum_id=" + id,
      "GET",
      {},
      null,
      true
    ).then((curriculum) => {
      dispatch({
        type: curriculumConstants.GET_DETAIL_CURRICULUM,
        curriculum,
      });
    });
  };
}

function getDataMapCurriculum(id) {
  return (dispatch) => {
    return apiCaller(
      "/student/api_student_curriculum/map_unit?curriculum_id=" + id,
      "GET",
      {},
      null,
      true
    ).then((data_map) => {
      dispatch({
        type: curriculumConstants.GET_DATA_MAP_CURRICULUM_STUDENT,
        data_map,
      });
    });
  };
}

function getDataMapByClassId(id, dataMap) {
  return (dispatch) => {
    if (!!dataMap?.data?.length) {
      dispatch({
        type: curriculumConstants.GET_DATA_MAP_CURRICULUM_STUDENT,
        data_map: dataMap,
        classId: id
      });
      return dataMap
    }
    return apiCaller(
      "/student/api_student_curriculum/map_unit?class_id=" + id,
      "GET",
      {},
      null,
      true
    ).then((data_map) => {
      dispatch({
        type: curriculumConstants.GET_DATA_MAP_CURRICULUM_STUDENT,
        data_map,
        classId: id
      });
    });
  };
}

function getDetailCurriculumPreparationCourse(curriculumId, dataMap ) {
  // return (dispatch) => {
  //   if (!!dataMap?.data?.length) {
  //     dispatch({
  //       type: curriculumConstants.GET_DATA_MAP_CURRICULUM_STUDENT,
  //       data_map: dataMap,
  //       curriculumId
  //     });
  //     return dataMap
  //   }
    return apiCaller("/api_preparation_course?curriculum_id=" + curriculumId, "GET")
    // .then((res) => {
    //   const listData = res?.data
    //   dispatch({
    //     type: curriculumConstants.GET_DATA_MAP_CURRICULUM_STUDENT,
    //     data_map: {
    //       ...res,
    //       data_lesson: {
    //         data: [...listData],
    //         unit_name: listData?.map(item => ({
    //           list_skill: [],
    //           ...item
    //         }))
    //       },
    //     },
    //     curriculumId
    //   });
    // })
  // }
}

function getDataGridCurriculum(id, isLoading) {
  return (dispatch) => {
    return apiCaller(
      "/api_class/class_curriculum?curriculum_id=" + id,
      "GET",
      {},
      null,
      true,
      configConstants.API_URL_SETEST,
      isLoading
    ).then((data_grid) => {
      dispatch({
        type: curriculumConstants.GET_DATA_GRID_CURRICULUM_STUDENT,
        data_grid,
      });
    });
  };
}

function getListCurriculum() {
  return (dispatch) => {
    return apiCaller(
      "/student/api_student_curriculum/courses_name_follow_class_v2",
      "GET",
      {},
      null,
      true
    ).then((data_grid) => {
      dispatch({
        type: curriculumConstants.GET_LIST_CURRICULUM_STUDENT,
        data_grid,
      });
    });
  };
}

function getLessonCurriculum(unit_id, isLoading) {
  return (dispatch) => {
    return apiCaller(
      "/student/api_student_curriculum/lessons_by_skill?unit_id=" + unit_id,
      "GET",
      {},
      null,
      true,
      configConstants.API_URL_SETEST,
      isLoading ?? true
    )
      .then((lessons) => {
        dispatch({
          type: curriculumConstants.GET_LESSON_CURRICULUM_STUDENT,
          lessons,
        });
      })
      .catch((error) => {
        dispatch(
          alertActions.success({
            message: error.toString(),
            screen: studentConstants.SCREEN_LESSSON_SKILL,
          })
        );
      });
  };
}

function getLessonByTopicStudent({topicId, curriculumId, unitId, limit = 10, offset = 0, addressId, year, essay, classId}) {
  return apiCaller(`/api_preparation_course/lesson_by_topic?unit_id=${unitId}&curriculum_id=${curriculumId}&class_id=${classId}&topic_id=${topicId}&offset=${offset}&limit=${limit}&address_id=${addressId}&year=${year}&essay=${essay}`, "GET")
}

function getListClass() {
  return (dispatch) => {
    return apiCaller("/api_class/my_classes", "GET", {}, null, true).then(
      (classes) => {
        dispatch({
          type: teacherConstants.GET_LIST_CLASS,
          classes: classes,
        });
      }
    );
  };
}

function checkParent(data) {
  return async (dispatch) => {
    try {
      const resCheckParent = await studentService.checkParent(data?.user_code)
      if(!!resCheckParent?.status) {
        const infoParent = resCheckParent?.data
        const addStudent = {
          user_data: {
            ...infoParent,
            user_code: data.user_code
          }
        };
        dispatch({
          type: popupConstants.SHOW_FORM_POPUP,
          data: {
            showFormAddStudent: true,
          },
        });
        dispatch(alertActions.clear());
        dispatch({
          type: teacherConstants.ADD_STUDENT,
          data: addStudent,
        });
      } else {
        const statusRelationship = resCheckParent?.statusRelationship
        let isShowPopup = false;
        let hasBtnClosePopup = false;
        let textComplete = '';
        let textClose = '';
        let onComplete = () => {
          history.goBack()
        }
        let onClosePopup = undefined;
        if (statusRelationship === 1) {
          isShowPopup=true
        } else if(statusRelationship === 2) {
          isShowPopup = true;
          hasBtnClosePopup = true;
          textComplete = 'Đồng ý';
          textClose = 'Không';
          onComplete = async () => {
            try {
              const res = await studentService.acceptInvitationParent({parent_id: resCheckParent?.parent_id})
              dispatch(alertActions.clear())
              dispatch(
                alertActions.success({
                  message: res?.msg,
                  screen: studentConstants.SCREEN_ADD_PARENT,
                  isShowPopup,
                  onComplete: () => {
                    history.goBack()
                  },
                })
              );
            } catch (err) {
              dispatch(alertActions.clear())
              dispatch(
                alertActions.error({
                  message: err.toString(),
                  screen: studentConstants.SCREEN_ADD_PARENT,
                  isShowPopup
                })
              );
            }
          };
          onClosePopup = async () => {
            try {
              const res = await studentService.removeParent(resCheckParent?.parent_id, 2)
              dispatch(alertActions.clear())
              dispatch(
                alertActions.success({
                  message: res?.msg,
                  screen: studentConstants.SCREEN_ADD_PARENT,
                  isShowPopup,
                  onComplete: () => {
                    history.goBack()
                  },
                })
              );
            } catch (err) {
              dispatch(alertActions.clear())
              dispatch(
                alertActions.error({
                  message: err.toString(),
                  screen: studentConstants.SCREEN_ADD_PARENT,
                  isShowPopup
                })
              );
            }
          }
        }
        dispatch(
          alertActions.error({
            message: resCheckParent?.msg,
            screen: studentConstants.SCREEN_ADD_PARENT,
            isShowPopup,
            onComplete,
            hasBtnClosePopup,
            textComplete,
            textClose,
            onClosePopup
          })
        );
      }
    } catch (error) {
      dispatch(
        alertActions.error({
          message: error.toString(),
          screen: studentConstants.SCREEN_ADD_PARENT,
        })
      );
    }
  };
}

function addParent(data) {
  return (dispatch) => {
    dispatch({
      type: popupConstants.CLEAR_ALL_POPUP,
    });
    return apiCaller(
      "/api_relationship/send_request",
      "POST",
      data,
      null,
      false
    ).then(
      (res) => {
        if (
          res.code === null ||
          res.code === "MTY0OTc4MTc5NA==" ||
          res.msg ===
            "Bạn đã tạo link liên kết với tài khoản này trước đó. Vui lòng đợi phụ huynh xác nhận."
        ) {
          dispatch(
            alertActions.error({
              message:
                "Bạn đã gửi yêu cầu liên kết với tài khoản này trước đó. Vui lòng đợi phụ huynh xác nhận.",
              screen: studentConstants.SCREEN_ADD_PARENT,
            })
          );
        } else {
          dispatch(
            alertActions.success({
              message: studentConstants.ADD_PARENT_COMPLETE,
              screen: studentConstants.SCREEN_ADD_PARENT,
            })
          );
        }
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: studentConstants.SCREEN_ADD_PARENT,
          })
        );
      }
    );
  };
}

function applyForClass(data) {
  return (dispatch) => {
    dispatch({
      type: popupConstants.CLEAR_ALL_POPUP,
    });
    return apiCaller(
      "/api_class/class_request",
      "POST",
      data,
      null,
      false
    ).then(
      (data) => {
        dispatch(
          alertActions.success({
            message: data.msg,
            screen: studentConstants.SCREEN_APPLY_CLASS,
          })
        );
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: studentConstants.SCREEN_APPLY_CLASS,
          })
        );
      }
    );
  };
}

function checkClass(data) {
  return (dispatch) => {
    return apiCaller(
      "/api_class/code?class_code=" + data.class_code,
      "GET",
      {},
      null,
      false
    ).then(
      (applyClass) => {
        applyClass.class_data.class_code = data.class_code;
        dispatch({
          type: popupConstants.SHOW_FORM_POPUP,
          data: {
            showFormApplyClass: true,
          },
        });
        dispatch(alertActions.clear());
        dispatch({
          type: teacherConstants.GET_DETAIL_CLASS,
          class: applyClass,
        });
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: studentConstants.SCREEN_APPLY_CLASS,
          })
        );
      }
    );
  };
}

function getAssessmentsOnline(student_id) {
  return (dispatch) => {
    return apiCaller(
      "/api_student/student_assessment?student_id=" + student_id,
      "GET",
      {},
      null,
      true
    ).then((assessments) => {
      if (assessments.data_assessment) {
        dispatch({
          type: studentConstants.GET_ASSESSMENT_ONLINE,
          assessments,
        });
      }
    });
  };
}

function getLogLearningByDate(date, id) {
  return (dispatch) => {
    return apiCaller(
      `/api_student/log_learning_by_date?date=${date}&student_id=${id}`,
      "GET",
      {},
      null,
      true
    ).then((learnings) => {
      dispatch({
        type: studentConstants.GET_LOG_LEARNING_BY_DATE,
        learnings,
      });
    });
  };
}

function getListLogLearning(from_time, student_id) {
  return (dispatch) => {
    return apiCaller(
      "/api_student/student_log_learning?from_time=" +
        from_time +
        "&student_id=" +
        student_id,
      "GET",
      {},
      null,
      true
    ).then((learnings) => {
      dispatch({
        type: studentConstants.GET_LIST_LOG_LEARNING,
        learnings,
      });
    });
  };
}

function updateDefaultCurriculum(curriculumId) {
  let data = {
    curriculum_id: curriculumId,
  };
  return apiCaller(
    "/api_student/save_default_curriculum",
    "POST",
    data,
    null,
    false
  ).then(
    (res) => {
      return res;
    },
    (error) => {
      return false;
    }
  );
}

function updateDefaultCurriculumFirst(curriculumId) {
  let data = {
    curriculum_id: curriculumId,
  };
  return apiCaller(
    "/api_student/save_user_curriculum",
    "POST",
    data,
    null,
    false
  ).then(
    (res) => {
      return res;
    },
    (error) => {
      return false;
    }
  );
}
