import React, {useState, useEffect} from 'react';
import { toListDownLine } from '../../../_helpers/utils';
import { CheckBoxCustom, renderStatusCheckBox } from '../../../_components/exam-test/CheckBoxCustom';

const ResultQuestionChooseChoice = (props) => {
    const {dataQuestion, isReview, answer, infoQuestion, skill, question_type} = props;
    const finalAnswer = JSON.parse(answer)

    const renderOption = (item, iQues) => {
      const isChoose = item === finalAnswer?.[iQues]?.user_choice;
      const isRightOption = dataQuestion?.[iQues]?.list_option?.[0]?.match_option_text?.includes(item);
      let matchOptionText = item;
      if (skill == 'reading' && question_type == 5) {
        switch (matchOptionText) {
            case 'T': 
                matchOptionText = 'True';
                break;
            case 'F':
                matchOptionText = 'False';
                break;
            default: matchOptionText = 'Not Given'
        }
    }

      return (
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems:'center'
        }}>
          <div 
            style={{
              marginLeft: 16,
              marginRight: 6,
              width: 24,
              height: 24,
            }}
          >
            {isRightOption && !Number(finalAnswer?.[iQues]?.score) ? <img 
              src="/assets/images/ico_hover_answer.png"
              style={{
                width: 24,
                height: 24,
              }}
            /> : null}
          </div>
          <CheckBoxCustom
            status={renderStatusCheckBox(
              isChoose,
              isRightOption,
              true
            )}
            label={matchOptionText}
          />
        </div>
      )
    }

    const renderUnderLine=(s, beginNum)=>{
        var matchs = s.match(/\[(.*?)\]/g);
        matchs.forEach(e=>{
            s = s.replace(e,' ____ ')
        })
        let c = 0;
        var bodyT = toListDownLine(s)
        return(
          <div style={{display: 'flex',flexDirection:'column'}}>
              {
                  bodyT.map((mono, mKey) => {
                      return(
                          <div key={mKey} style={{display: 'flex',flexDirection:'row',flexWrap:'wrap'}}>
                          { mKey == 0 && <div style={{
                                      justifyContent:'center',
                                  }}>
                                      <p style={{marginRight: 4}}>{props?.numberReview ? props?.numberReview : (beginNum + 1)}. </p>
                                  </div>}
                          {
                              mono.split(' ').map((e,k)=>{
                                  return <div key={k} style={{
                                      borderBottomStyle: 'solid',
                                      borderBottomColor: '#000',
                                      borderBottomWidth:e==='____'?1:0,
                                      justifyContent:'center',
                                      marginRight:2
                                  }}>
                                      {
                                          e==='____'?<p key={mKey+"_"+k} style={{}}>{matchs[c++].replace(/[\[\]]/g,'')}</p>:
                                              <p style={{marginRight:2}}  key={mKey+"-"+k}>{e}</p>
                                      }
                                  </div>
                              })
                          }
                      </div>
                      )
                  })
              }
          </div>
        )
    }

    const paragraph = infoQuestion?.lesson_paragraph || infoQuestion?.paragraph;

    return (
        <div style={{flex: 1,marginTop:10}}>
          {paragraph ? 
            <p style={{
              paddingBottom: 16
            }}>
              {paragraph?.trim()}
            </p>
          : null
          }
          <div style={{display: 'flex', flexDirection: 'column', gap: 32}}>
            {dataQuestion?.map((ques, iQues) => {
              let listOption = [];
              if((skill == 'reading' && question_type == 5)) {
                listOption = ['T', 'F', 'NG']
              } else {
                listOption = ques?.list_option?.[0]?.question_content?.match(/\[(.*?)\]/g)?.map(item => item?.replace('[', '')?.replace(']', ''))
              }
              const explainQuestion = ques?.list_option[0]?.question_explain?.content_question_text
                                      || ques?.list_option[0]?.option_explain || "";
              return (
                <div key={iQues}>
                  <div>
                    {
                        ques?.list_option[0].question_content.indexOf('[')>=0
                        &&ques?.list_option[0].question_content.indexOf(']')>=0?
                        renderUnderLine(ques?.list_option[0].question_content, iQues)
                        :
                        <p>
                            {(props?.numberReview ? props?.numberReview : (iQues + 1)) + '. ' + ques?.list_option[0].question_content}
                        </p>
                    }
                  </div>
                  <div style={{marginTop: 12}}>
                    {listOption?.map((option, iOption) => {
                      return (
                        <div key={iOption}>
                          {renderOption(option, iQues)}
                        </div>
                      )
                    })}
                  </div>
                  {!!explainQuestion && <div
                    >
                        <p style={{fontWeight: 600}}>Giải thích:</p>
                        <p style={{whiteSpace: 'pre-wrap'}}>{explainQuestion}</p>
                    </div>
                  }
                </div>     
              )
            })}
          </div>
        </div>
    );
};

export default ResultQuestionChooseChoice;
